import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Navbar from "../../../Parts/Navbar";
import { ToastContainer } from "react-toastify";
import "./success.css";
import { useAtom } from "jotai";
import { sampleURL, chunkStore } from "../../../../Store/Users";
import Player from "../../../Parts/Questions/Player";
import Header from "../../../Parts/Questions/Header";

const Index = () => {
  const history = useHistory();
  const [timer, setTimer] = useState(600);
  const que_time = 180;
  const [queTime, setQueTime] = useState(que_time);
  const [sampleAud] = useAtom(sampleURL);
  const [, setChunkStore] = useAtom(chunkStore);

  const startTotTimer = () => {
    setInterval(() => {
      setTimer((timer) => timer - 1);
    }, 1000);
  };

  const startQueTimer = () => {
    setInterval(() => {
      setQueTime((queTime) => queTime - 1);
    }, 1000);
  };

  useEffect(() => {
    startTotTimer();
    startQueTimer();
    return () => {
      setChunkStore([]);
      // window.location.reload();
    }
  }, []);
  return (
    <>
      <h2 className="text-center theme-primary-blue-text">Sample Interview</h2>
      <Header
        total_timer={timer}
        quesion_text={
          "Please briefly introduce yourself in 2-3 mins (ideally covering your education, professional experience, hobbies etc.)"
        }
        question_sno={1}
        total_questions={1}
        question_timer={queTime}
        max_int_time={600}
      />
      <Player
        question_timer={queTime}
        question_time={que_time}
        question_sno={1}
        sample={true}
      />
      <div className="text-center mt-4">
        <h2 className="theme-primary-blue-text">Test Your Recording Here</h2>
        <audio src={sampleAud} controls></audio>
        <br />
        <a
          onClick={(e) => {
            history.goBack()
          }}
          className="btn btn-primary mt-4"
        >
          Back to Interview
        </a>
      </div>
      <ToastContainer position="bottom-center" />
    </>
  );
};

export default Index;
