import React, { useState } from "react";
import "./Player.css";
const Index = ({
  id,
  start,
  stop,
  deleteAudio,
  recordingStatus,
  blob,
  duration,
}) => {
  // Recording Timer
  const [timer, setTimer] = useState(0);
  const minutes = `${Math.floor(timer / 60)}`;

  const clockTimer = (stop) => {
    const interval = setInterval(() => {
      setTimer((timer) => timer + 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(interval);
    }, duration * 60 * 1000);

    if (stop) {
      clearInterval(interval);
    }
  };

  return (
    <div className="all-container">
      <div className="player-container">
        <div className="clock">
          <i className="fas fa-clock"></i>
          <h6>{duration} min</h6>
        </div>
        <div className="pause-play">
          {!recordingStatus ? (
            <div
              className="pause-play-btn"
              onClick={() => {
                clockTimer();
                return start(id, "", duration * 60);
              }}
            >
              <div className="start-btn"></div>
            </div>
          ) : (
            <div
              className="pause-play-btn"
              onClick={() => {
                clearInterval(true);
                return stop(id);
              }}
            >
              <div className="stop-btn"></div>
            </div>
          )}
        </div>
        <div className="timer">
          {`0${minutes % 60}`.slice(-2)} : {`0${timer % 60}`.slice(-2)}
        </div>
        <div className="play-slider">
          <audio id="track" controls src={blob}></audio>
        </div>
        <div className="delete">
          <i
            className="fas fa-trash-alt"
            onClick={() => {
              setTimer(0);
              return deleteAudio(id);
            }}
          ></i>
        </div>
      </div>
    </div>
  );
};

export default Index;
