import React from "react";
import Navbar from "../../../Parts/Navbar";
import Hero from "../../../Parts/Hero";

const Index = () => {
  return (
    <>
      <Hero />
    </>
  );
};

export default Index;
