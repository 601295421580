import React from "react";
import Navbar from "../../../Parts/Navbar";
import RulesContainer from "../../../Parts/Rules";

const index = () => {
  return (
    <div>
      <RulesContainer />
    </div>
  );
};

export default index;
