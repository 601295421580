import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Rules.css";
// API CALLS
import { useQuery, useMutation } from "react-query";
import { selectJob } from "../../API_CALLS/Users";
// Store
import { useAtom } from "jotai";
import {
  selectedJob,
  totalTimer,
  questions,
  currentQuesstion,
  totalInterval,
  onlyTotalTime,
  jobRole,
  currentRole,
} from "../../../Store/Users";
import { useHistory } from "react-router";
import { ToastContainer, toast } from "react-toastify";

function RouterQuery() {
  return new URLSearchParams(useLocation().search);
}

const Index = () => {
  let query = RouterQuery();

  const [, setCurrentQue] = useAtom(currentQuesstion);
  const [interview, setInterview] = useAtom(selectedJob);
  const [totalInt, setTotalInt] = useAtom(totalInterval);
  const [positions] = useAtom(jobRole);
  const jobID = query.get("job_id");
  const candidate_job_id = query.get("id");
  const [, setQuestion] = useAtom(questions);
  const [, setTimer] = useAtom(totalTimer);
  const [, setTotTimer] = useAtom(onlyTotalTime);
  const [role, setRole] = useAtom(currentRole);
  const history = useHistory();

  // Microphone
  const [isBlocked, setIsBlocked] = useState(false);

  const { mutateAsync } = useMutation(selectJob);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      setCurrentQue(0);
      selectedInterview(jobID);
      setRole(
        positions.filter((data) => {
          return data.id === parseInt(candidate_job_id);
        })
      );
    }
    return () => {
      isMounted = false;
      clearInterval(totalInt);
    };
  }, [jobID]);

  useEffect(() => {
    if (window.chrome !== undefined) {
      navigator.permissions
        .query({ name: "microphone" })
        .then(function (permissionStatus) {
          if (permissionStatus.state === "prompt") {
            setIsBlocked(true);
            navigator.mediaDevices
              .getUserMedia({ video: false, audio: true })
              .then((mediaStream) => {
                setIsBlocked(false);
                window.requestStream = mediaStream;
              })
              .catch((err) => {
                console.log(err);
                if (err) {
                  setIsBlocked(true);
                }
              });
          }
          if (permissionStatus.state === "granted") {
            setIsBlocked(false);
          }
          if (permissionStatus.state === "denied") {
            setIsBlocked(true);
          }
        });
    }
  }, []);

  const startTimer = () => {
    setTotalInt(
      setInterval(() => {
        setTimer((timer) => timer - 1);
      }, 1000)
    );
    if (window?.requestStream !== undefined) {
      window?.requestStream?.getTracks().forEach((track) => {
        track.stop();
      });
    }
  };

  const selectedInterview = async (id) => {
    try {
      const response = await mutateAsync(id);
      setInterview(response);
      setTimer(response?.total_time);
      setTotTimer(response?.total_time);
      const filteredQue = response.questions_details.filter(
        (data) => data.answered !== true
      );

      if (filteredQue === undefined) {
        history.push("/success");
      } else {
        setQuestion(filteredQue);
        if (filteredQue.length === 0) {
          history.push("/success");
        }
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error("Job not found", {
          position: "bottom-center",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
      history.push("/");
    }
  };

  return (
    <div className="up-card-container custom-container">
      <div className="card">
        <div className="header">{role ? role[0]?.job_title : null}</div>
        <h4 className="theme-primary-blue-text text-center">
          <u>Important points to remember</u>
        </h4>
        <div className="body">
          <span className="theme-primary-gray-text"></span>
          <div className="notes-by-hiring-tool">
            <ul>
              <li>
                - Total duration of this interview is
                <span className="theme-primary-blue-text">
                  {" "}
                  {`0${Math.floor(interview?.total_time / 60) % 60}`.slice(-2)}:
                  {`0${interview?.total_time % 60}`.slice(-2)} minutes
                </span>
                . There are {interview?.total_questions} questions.
              </li>
              {interview?.questions_details.map((question, index) => {
                return (
                  <li key={index}>
                    - Question ({index + 1}) needs to be answered within
                    <span className="theme-primary-blue-text">
                      {" "}
                      {`0${Math.floor(question?.time_alloted / 60) % 60}`.slice(
                        -2
                      )}
                      :{`0${question?.time_alloted % 60}`.slice(-2)} mins
                    </span>{" "}
                    .
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="other-instruction">
            <p>
              - We recommend you to use latest version of Google Chrome to avoid
              any issues during your interview.
            </p>
            <p>
              - Once you "Start Interview" You cannot stop the{" "}
              {`0${Math.floor(interview?.total_time / 60) % 60}`.slice(-2)}:
              {`0${interview?.total_time % 60}`.slice(-2)} minutes interview
              timer, so please make sure to start only when you are free to
              finish interview in a single go.
            </p>
            <p>
              - The interview will automatically end as soon as the interview
              duration of{" "}
              {`0${Math.floor(interview?.total_time / 60) % 60}`.slice(-2)}:
              {`0${interview?.total_time % 60}`.slice(-2)} mins is completed,
              regardless of how many questions you have answered.
            </p>
            <p>
              - If you are not sure about how our hiring tool works,{" "}
              <Link to="/sample-interview">
                Click here to take a sample interview first.
              </Link>
            </p>
            <p>
              - We strongly recommend that you record your answers in a
              noise-free room. Please close all doors and windows, and make sure
              that the sound of fan/AC is minimal/zero. <br /> You can take our
              sample interview first to see if your audio recordings are free of
              any ambient noises for crystal clear recordings.
            </p>
            <p>
              - We have added some buffer time to interview duration taking into
              account internal issues and power issues, but please ensure you
              are on stable and fast internet connection on a laptop <br /> with
              sufficient battery backup.
            </p>
          </div>
        </div>
      </div>
      <div className="text-center mt-4">
        {isBlocked ? (
          <h6 className="text-danger text-center">
            Enable your microphone before starting the Interview
          </h6>
        ) : (
          <Link
            to={`/questions?job_id=${jobID}&id=${candidate_job_id}`}
            onClick={startTimer}
            className="btn btn-primary"
          >
            Start Interview
          </Link>
        )}
      </div>
      <ToastContainer position="bottom-center" />
    </div>
  );
};

export default Index;
