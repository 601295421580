import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../../../Parts/Navbar";
import "./success.css";
import { useAtom } from "jotai";
import { currentRole } from "../../../../Store/Users";

const Index = () => {
  const [role] = useAtom(currentRole);
  return (
    <>
      <div className="success-submit text-center">
        <h4>Your interview is successfully submitted.</h4>
        <div className="card">
          Thanks for taking out time to attend this interview! Your interview is
          successfully submitted for <b>{role?.[0]?.job_title}</b>
          <br />
          It might take 2-5 days for us to evaluate your submission. Based on
          our assessment, you will receive an email notifying you whether you
          are shortlisted for next round of interview, or if your candidature is
          dropped.
          <br />
          If you faced any problems during the interview, please use this form
          to share your feedback:{" "}
          <a target="_blank" href="https://forms.gle/teHkzNwNT1PYWdKX9">
            https://forms.gle/teHkzNwNT1PYWdKX9
          </a>
        </div>
      </div>
      <div className="text-center ">
        <Link to="/" className="btn btn-primary">
          Dashboard
        </Link>
      </div>
    </>
  );
};

export default Index;
