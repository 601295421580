import * as Yup from 'yup';
const url_reg = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];
const SUPPORTED_FORMATS_RESUME = ['application/pdf'];
const bytesToMb = (inputFileSize) => {
    return (inputFileSize * 1000) * 1000
}


Yup.addMethod(Yup.string, 'stripEmptyString', function () {
    return this.transform((value) => (value === '' ? undefined : value));
});

Yup.addMethod(Yup.mixed, 'noImage', function () {
    return this.transform((value) => (value.length === 0 ? undefined : value));
});

const validationSchema = Yup.object().shape({
  resume: Yup.mixed()
  .test('fileType', "Resume is required", value => value.length)
  .test('fileType', "File format must be pdf", value => SUPPORTED_FORMATS_RESUME.includes(value[0]?.type))
  .test('fileSize', " Max file size allowed is 10 MB", value => value[0]?.size <= bytesToMb(10))
  .test('fileSize', "File is empty, input a valid file", value => value[0]?.size > 0),
  about: Yup.string()
      .min(10, "About should be minumum of 10 characters")
      .max(300, "About should be maximum of 300 characters")
      .required('About field is required'),
  profile_pic: Yup.mixed()
    .test('fileType', "Unsupported File Format", value => !value.length || (SUPPORTED_FORMATS.includes(value[0]?.type)))
    .test('fileSize', " Max file size allowed is 1 MB", value => !value.length || (value[0]?.size <= bytesToMb(1)))
    .test('fileSize', "Invalid file", value => !value.length || (value[0]?.size > 0)),
  linked_in: Yup.string()
      .required("Linked in profile is required").matches(url_reg, "Enter a valid URL"),
  facebook: Yup.string().matches(url_reg, "Enter a valid URL").stripEmptyString().nullable(),
  angellist: Yup.string().matches(url_reg, "Enter a valid URL").stripEmptyString().nullable(),
  twitter: Yup.string().matches(url_reg, "Enter a valid URL").stripEmptyString().nullable(),
  years_of_experience: Yup.number()
      .typeError('You must specify a number')
      .min(0, "Minimum value should be zero")
      .when("fresher", {
        is: true,
        then: Yup.number().positive().typeError('You must specify a number').notRequired(),
        otherwise: Yup.number().positive().typeError('Years of experience is required').required()
      }),
  current_ctc_fixed: Yup.number()
      .typeError('You must specify a number')
      .min(0, "Minimum value should be zero")
      .when("fresher", {
        is: true,
        then: Yup.number().typeError('You must specify a number').notRequired(),
        otherwise: Yup.number().typeError("Current CTC (Fixed) is required").required()
      }),
  current_ctc_variable: Yup.number()
      .typeError('You must specify a number')
      .min(0, "Minimum value should be zero")
      .when("fresher", {
        is: true,
        then: Yup.number().typeError('You must specify a number').notRequired(),
        otherwise: Yup.number().typeError("Current CTC (Variable) is required").required()
      }),
  expected_ctc: Yup.number()
      .typeError('Expected CTC is required')
      .required(),
  notice_period: Yup.number()
      .typeError('You must specify a number')
      .required("Notice period is required")
      .min(0, "Minimum value should be zero"),
  fresher: Yup.bool(),
  negotiable: Yup.bool(),
  skills: Yup.array()
      .required()
      .min(2, "Add atleast two skills"),
  roles: Yup.array()
      .required()
      .min(1, "Role is required"),
  locations: Yup.array()
      .required()
      .min(1, "Location is required"),
  languages: Yup.array()
      .required()
      .min(1, "Language is required"),
  
});

export default validationSchema;