import React, { useEffect } from 'react'
import { useForm, Controller  } from "react-hook-form";
import Creatable from "react-select/creatable";
import { withAsyncPaginate } from "react-select-async-paginate";
import { yupResolver } from '@hookform/resolvers/yup';
import { SpinnerCircular } from 'spinners-react';

// API Calls
import { useMutation } from 'react-query';
import { skillList, candidateProfessionalDetail, locationList, rolesList, languageList } from '../../../../API_CALLS/Sign-up';
// Store
import { useAtom } from 'jotai';
import { sign_up_pointer, is_fresher } from '../../../../../Store/Signup';
// Schema
import validationSchema from './validation/validation_professional';
const CreatableAsyncPaginate = withAsyncPaginate(Creatable);
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];
const bytesToMb = (inputFileSize) => {
    return (inputFileSize * 1000) * 1000
}


const ProfessionalDetails = () => {
  const [pointer, setPointer] = useAtom(sign_up_pointer);
  const [, setFresher] = useAtom(is_fresher)
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState: { errors }, control, watch, setValue, setError } = useForm(formOptions);
  const defaultAdditional = {
    page: 1
  };
  const skill_list_API = useMutation(skillList);
  const location_list_API = useMutation(locationList);
  const roles_list_API = useMutation(rolesList);
  const language_list_API = useMutation(languageList);
  const candidateProfessional = useMutation(candidateProfessionalDetail);
  const isFresher = watch("fresher");
  const profilePic = watch("profile_pic");

  if (isFresher) {
    setValue("years_of_experience", "")
    setValue("current_ctc_variable", "")
    setValue("current_ctc_fixed", "")
  } 

  const convertToFormData = (data) => {
    const formData = new FormData();
    Object.keys(data).map((key) => {
      if (key === "profile_pic" || key === "resume") {
        if (data[key][0]) {
          return formData.append(key, data[key][0])
        }
        return;
      }
      if (key === "skills" || key === "languages" || key === "locations" || key === "roles") {
        return data[key].map((val) => {
          formData.append(key, val.value);
        })
      }
      return formData.append(key, data[key])
    })
    return formData;
  }

  const onSubmit = data => {
    candidateProfessional.mutateAsync(convertToFormData(data)).then(() => {
      setPointer(pointer + 1);
    }).catch((e) => {
      console.log("Internal server error: ", e);
    })
  };

  let val = [];
  const loadOptions = async (search, page,attr) => {
    let res = [];
    val = [];
    switch(attr) {
      case "skill":{
        res = await skill_list_API.mutateAsync({ search });
        await res.map((option) => {
          val.push({ value: option[attr], label: option[attr] });
        })
        return {options: val, hasMore: false};
      }
      case "location":
        res = await location_list_API.mutateAsync({ search });
        await res.map((option) => {
          val.push({ value: option[attr], label: option[attr] });
        })
        return {options: val, hasMore: false};
      case "role":
        res = await roles_list_API.mutateAsync({ search });
        await res.map((option) => {
          val.push({ value: option[attr], label: option[attr] });
        })
        return {options: val, hasMore: false};
      case "language":
        res = await language_list_API.mutateAsync({ search });
        await res.map((option) => {
          val.push({ value: option[attr], label: option[attr] });
        })
        return {options: val, hasMore: false};
    }
  };

  const createImageErr = (profilePic) => {
    if (!SUPPORTED_FORMATS.includes(profilePic?.[0]?.type) && profilePic?.length) {
      return "Unsupported File Format"; 
    }
    if (!(profilePic?.[0]?.size <= bytesToMb(1)) && profilePic?.length) {
      return " Max file size allowed is 1 MB"
    }
    if(!(profilePic?.[0]?.size > 0) && profilePic?.length) {
      return " Invalid file"
    }
    return null;
  }


  return (
    !candidateProfessional.isLoading ?
    <>
      <div className='container mt-4'>
        <form className="row justify-content-center" onSubmit={handleSubmit(onSubmit)}>
          <div className="col-md-8 mt-5">
            <div className="row mt-2">
              <label htmlFor="resume" className="col-sm-2 col-form-label">
              Resume
              <div>(PDF only, max 10 MB)</div>
              </label>
              <div className="col-sm-10">
                <label htmlFor="resume"></label>
                <input type="file" className='btn' {...register("resume")} id="resume" />
                <div className="text-danger">
                  {errors.resume && errors.resume.message}
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <label htmlFor="About" className="col-sm-2 col-form-label">
              About
              </label>
              <div className="col-sm-10">
                <textarea id="About" className={'form-control ' + (errors.about && "border-danger")} {...register("about")} />
                <span className="text-danger">
                  {errors.about && errors.about.message}
                </span>
                <div className='text-end'>{!errors.about && "Max 300 characters"}</div>
              </div>
            </div>
            <div className="row mt-2">
              <label htmlFor="profile_pic" className="col-sm-2 col-form-label">
              Profile Picture
              </label>
              <div className="col-sm-10">
                <label htmlFor="profile_pic"></label>
                <input type="file" className='btn' {...register("profile_pic")} id="profile_pic" />
                &nbsp;&nbsp;&nbsp;
                {profilePic?.length && createImageErr(profilePic) === null ? <img src={profilePic?.length ? URL.createObjectURL(profilePic?.[0]): ""} width={100} height={100} alt="image" style={{borderRadius: "50%"}} />: null}
                <div className="text-danger">
                  {createImageErr(profilePic)}
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <label htmlFor="linked_in" className="col-sm-2 col-form-label">
              Your Linkedin profile link*
              </label>
              <div className="col-sm-10">
                <input type="text" id="linked_in" className={'form-control ' + (errors.linked_in && "border-danger")} {...register("linked_in")} />
                <span className="text-danger">
                  {errors.linked_in && errors.linked_in.message}
                </span>
              </div>
            </div>
            <div className="row mt-2">
              <label htmlFor="facebook" className="col-sm-2 col-form-label">
                Facebook (leave empty if not applicable)
              </label>
              <div className="col-sm-10">
                <input type="text" id="facebook" className={'form-control ' + (errors.facebook && "border-danger")} {...register("facebook")} />
                <span className="text-danger">
                  {errors.facebook && errors.facebook.message}
                </span>
              </div>
            </div>
            <div className="row mt-2">
              <label htmlFor="angellist" className="col-sm-2 col-form-label">
              Angelist profile (leave empty if not applicable)
              </label>
              <div className="col-sm-10">
                <input id="angellist" className={'form-control ' + (errors.angellist && "border-danger")} {...register("angellist")} />
                <span className="text-danger">
                  {errors.angellist && errors.angellist.message}
                </span>
              </div>
            </div>
            <div className="row mt-2">
              <label htmlFor="twitter" className="col-sm-2 col-form-label">
              Twitter profile (leave empty if not applicable)
              </label>
              <div className="col-sm-10">
                <input type="text" id="twitter" className={'form-control ' + (errors.twitter && "border-danger")} {...register("twitter")} />
                <span className="text-danger">
                  {errors.twitter && errors.twitter.message}
                </span>
              </div>
            </div>
            <div className="row mt-2">
              <label htmlFor="years_of_experience" className="col-sm-2 col-form-label">
              Experience (in years)*
              </label>
              <div className="col-sm-10">
                <input type="number" step="0.1" id="years_of_experience" disabled={isFresher} className={'form-control ' + ((errors.years_of_experience && !isFresher) && "border-danger")} {...register("years_of_experience", { required: !isFresher, maxLength: 300 })} min={0} />
                <div className='form-check-inline mt-2'>
                  <input type="checkbox" id='fresher' {...register("fresher")} />
                  <label htmlFor="fresher">&nbsp; I am a fresher</label>
                  <div className="text-danger text-right">
                    {(errors.years_of_experience && !isFresher) && errors.years_of_experience.message}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <label className="col-sm-2 col-form-label">
              Current CTC*
              </label>
              <div className="col-sm-10 mt-3">
                <div className="row mt-2">
                  <label htmlFor="current_ctc_fixed" className="col-sm-2 col-form-label">
                  Current CTC (Fixed)*
                  </label>
                  <div className="col-sm-10">
                    <input type="number" id="current_ctc_fixed" disabled={isFresher} className={'form-control ' + ((errors.current_ctc_fixed && !isFresher) && "border-danger")} {...register("current_ctc_fixed", { required: !isFresher, maxLength: 300 })} min={0} />
                    <span className="text-danger">
                  {(errors.current_ctc_fixed && !isFresher) && errors.current_ctc_fixed.message}
                </span>
                  </div>
                </div>
                <div className="row mt-2">
                  <label htmlFor="current_ctc_variable" className="col-sm-2 col-form-label">
                  Current CTC (Variable)*
                  </label>
                  <div className="col-sm-10">
                    <input type="number" id="current_ctc_variable" disabled={isFresher} className={'form-control ' + ((errors.current_ctc_variable && !isFresher) && "border-danger")} {...register("current_ctc_variable", { required: !isFresher, maxLength: 300 })} min={0} />
                    <span className="text-danger">
                      {(errors.current_ctc_variable && !isFresher) && errors.current_ctc_variable.message}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <label htmlFor="expected_ctc" className="col-sm-2 col-form-label">
              Expected CTC*
              </label>
              <div className="col-sm-10">
                <input type="number" id="expected_ctc" className={'form-control ' + (errors.expected_ctc && "border-danger")} {...register("expected_ctc")} min={0} />
                <div className='form-check-inline mt-2'>
                  <input type="checkbox" id='negotiable' {...register("negotiable")} />
                  <label htmlFor="negotiable">&nbsp; Negotiable</label>
                </div>
                <span className="text-danger">
                  {errors.expected_ctc && errors.expected_ctc.message}
                </span>
              </div>
            </div>
            <div className="row mt-2">
              <label htmlFor="skills" className="col-sm-2 col-form-label">
                Skills*
              </label>
              <div className="col-sm-10">
                <Controller 
                 name="skills"
                 control={control}
                 rules={{ required: true }}
                 className={'form-control ' + (errors.skills && "border-danger")}
                 render={({ field: { onChange, value, ref } }) => {
                   return <CreatableAsyncPaginate
                     additional={defaultAdditional}
                     value={value}
                     loadOptions={async (q, prevOptions, option) => {
                      const { options, hasMore } = await loadOptions(q, option.page, "skill");
                      return {
                        options,
                        hasMore,
                        additional: {
                          page: option.page + 1
                        }
                      };
                    }}
                     onChange={onChange}
                     isMulti
                     closeMenuOnSelect={false}
                     debounceTimeout={1000}
                     allowCreateWhileLoading={true}
                     styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }), 
                      control: base => ({
                        ...base,
                        borderColor:errors.skills ? "#dc3545" : "#fff",
                        boxShadow: 'none'
                      }) }
                    }
                     inputRef={ref}
                   />
                 }}
                />
                <span className="text-danger">
                  {errors.skills && errors.skills.message}
                </span>
              </div>
            </div>
            <div className="row mt-2">
              <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
                Roles Interested in*
              </label>
              <div className="col-sm-10">
                <Controller 
                  name="roles"
                  control={control}
                  rules={{ required: true }}
                  className={'form-control ' + (errors.roles && "border-danger")}
                  render={({ field: { onChange, value, ref } }) => {
                    return <CreatableAsyncPaginate
                      additional={defaultAdditional}
                      value={value}
                      loadOptions={async (q, prevOptions, option) => {
                        const { options, hasMore } = await loadOptions(q, option.page, "role");
                        return {
                          options,
                          hasMore,
                          additional: {
                            page: option.page + 1
                          }
                        };
                      }}
                      onChange={onChange}
                      isMulti
                      closeMenuOnSelect={false}
                      debounceTimeout={1000}
                      allowCreateWhileLoading={true}
                      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }), 
                        control: base => ({
                          ...base,
                          borderColor:errors.roles ? "#dc3545" : "#fff",
                          boxShadow: 'none'
                        }) }
                      }
                      inputRef={ref}
                    />
                  }}
                />
                <span className="text-danger">
                  {errors.roles && errors.roles.message}
                </span>
              </div>
            </div>
            <div className="row mt-2">
              <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
                Preferred location*
              </label>
              <div className="col-sm-10">
                <Controller 
                    name="locations"
                    control={control}
                    rules={{ required: true }}
                    className={'form-control ' + (errors.locations && "border-danger")}
                    render={({ field: { onChange, value, ref } }) => {
                      return <CreatableAsyncPaginate
                        additional={defaultAdditional}
                        value={value}
                        loadOptions={async (q, prevOptions, option) => {
                      const { options, hasMore } = await loadOptions(q, option.page, "location");
                      return {
                        options,
                        hasMore,
                        additional: {
                          page: option.page + 1
                        }
                      };
                    }}
                        onChange={onChange}
                        isMulti
                        closeMenuOnSelect={false}
                        debounceTimeout={1000}
                        allowCreateWhileLoading={true}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }), 
                          control: base => ({
                            ...base,
                            borderColor:errors.locations ? "#dc3545" : "#fff",
                            boxShadow: 'none'
                          }) }
                        }
                        inputRef={ref}
                      />
                    }}
                  />
                  <span className="text-danger">
                  {errors.locations && errors.locations.message}
                </span>
              </div>
            </div>
            <div className="row mt-2">
              <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
                Languages*
              </label>
              <div className="col-sm-10">
                <Controller 
                    name="languages"
                    control={control}
                    rules={{ required: true }}
                    className={'form-control ' + (errors.languages && "border-danger")}
                    render={({ field: { onChange, value, ref } }) => {
                      return <CreatableAsyncPaginate
                        additional={defaultAdditional}
                        value={value}
                        loadOptions={async (q, prevOptions, option) => {
                      const { options, hasMore } = await loadOptions(q, option.page, "language");
                      return {
                        options,
                        hasMore,
                        additional: {
                          page: option.page + 1
                        }
                      };
                    }}
                        onChange={onChange}
                        isMulti
                        closeMenuOnSelect={false}
                        debounceTimeout={1000}
                        allowCreateWhileLoading={true}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }), 
                          control: base => ({
                            ...base,
                            borderColor:errors.languages ? "#dc3545" : "#fff",
                            boxShadow: 'none'
                          }) }
                        }
                        inputRef={ref}
                      />
                    }}
                  />
                  <span className="text-danger">
                  {errors.languages && errors.languages.message}
                </span>
              </div>
            </div>
            <div className="row mt-2">
              <label htmlFor="notice_period" className="col-sm-2 col-form-label">
                Notice Period (in days)*
              </label>
              <div className="col-sm-10">
                <div className="">
                  <input type='number' className={'form-control ' + (errors.notice_period && "border-danger")} {...register("notice_period", { required: true, maxLength: 300 })} id="notice_period" />
                  <span className="text-danger">
                    {errors.notice_period && errors.notice_period.message}
                  </span>
                </div>
              </div>
            </div>
            <div className="text-center mt-4 pb-4">
              <button className="btn btn-success">Next</button>
            </div>
          </div>
        </form>
      </div>
    </>: 
    <div className="text-center">
      <SpinnerCircular size={50} thickness={100} speed={100} color="#36ad47" secondaryColor="rgba(0, 0, 0, 0.44)" />
    </div> 
  )
}

export default ProfessionalDetails