import React from "react";
import { useAtom } from "jotai";
import SortableTbl from "react-sort-search-table";
import { useParams } from "react-router-dom";
import { shortListed } from "../../../../../../Store/Users";
import { useHistory } from "react-router-dom";
import "./details.css";
import { ToastContainer } from "react-toastify";
import { DataGrid, GridToolbar, GridCellE} from "@mui/x-data-grid";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';

const Index = ({ candidate }) => {
  const { position_id } = useParams();
  const [shortListedCandidate] = useAtom(shortListed);
  const [searchVal, setSearchVal] = React.useState("");
  const history = useHistory();


  function isOverflown(element) {
    return (
      element.scrollHeight > element.clientHeight ||
      element.scrollWidth > element.clientWidth
    );
  }
  
  const GridCellExpand = React.memo(function GridCellExpand(props) {
    const { width, value } = props;
    const wrapper = React.useRef(null);
    const cellDiv = React.useRef(null);
    const cellValue = React.useRef(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [showFullCell, setShowFullCell] = React.useState(false);
    const [showPopper, setShowPopper] = React.useState(false);
  
    const handleMouseEnter = () => {
      const isCurrentlyOverflown = isOverflown(cellValue.current);
      setShowPopper(isCurrentlyOverflown);
      setAnchorEl(cellDiv.current);
      setShowFullCell(true);
    };
  
    const handleMouseLeave = () => {
      setShowFullCell(false);
    };
  
    React.useEffect(() => {
      if (!showFullCell) {
        return undefined;
      }
  
      function handleKeyDown(nativeEvent) {
        // IE11, Edge (prior to using Bink?) use 'Esc'
        if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
          setShowFullCell(false);
        }
      }
  
      document.addEventListener('keydown', handleKeyDown);
  
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }, [setShowFullCell, showFullCell]);
  
    return (
      <Box
        ref={wrapper}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        sx={{
          alignItems: 'center',
          lineHeight: '24px',
          width: 1,
          height: 1,
          position: 'relative',
          display: 'flex',
        }}
      >
        <Box
          ref={cellDiv}
          sx={{
            height: 1,
            width,
            display: 'block',
            position: 'absolute',
            top: 0,
          }}
        />
        <Box
          ref={cellValue}
          sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
          {value}
        </Box>
        {showPopper && (
          <Popper
            open={showFullCell && anchorEl !== null}
            anchorEl={anchorEl}
            style={{ width, marginLeft: -17 }}
          >
            <Paper
              elevation={1}
              style={{ minHeight: wrapper.current.offsetHeight - 3 }}
            >
              <Typography variant="body2" style={{ padding: 8 }}>
                {value}
              </Typography>
            </Paper>
          </Popper>
        )}
      </Box>
    );
  });

  function renderCellExpand(params) {
    return (
      <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
    );
  }


  function timeConverter(UNIX_timestamp){
    var a = new Date(UNIX_timestamp * 1000);
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
    return time;
  }
  const columns = [
    { headerName: "Id", field: "id", type: 'number', width: 100 },
    { headerName: "Name", field: "name", width:200 },
    { headerName: "Position", field: "position", width: 200, renderCell: renderCellExpand },
    { headerName: "Status", field: "status", width: 150 },
    { headerName: "Notes", field: "notes", width: 200, renderCell: renderCellExpand },
    { headerName: "Total Time", field: "total_time", valueGetter: (params) => convertSeconds(params.row.total_time), width: 100 },
    { headerName: "Completed Time", field: "completed_time", valueGetter: (params) => convertSeconds(params.row.completed_time), width: 150 },
    { headerName: "Finished time", field: "finished_time", type:'date', valueGetter: (params) => timeConverter(params.row.finished_time), width: 200 },
  ];

  var convertSeconds = function (sec) {
    var hrs = Math.floor(sec / 3600);
    var min = Math.floor((sec - hrs * 3600) / 60);
    var seconds = sec - hrs * 3600 - min * 60;
    seconds = Math.round(seconds * 100) / 100;

    var result = hrs < 10 ? "0" + hrs : hrs;
    result += ":" + (min < 10 ? "0" + min : min);
    result += ":" + (seconds < 10 ? "0" + seconds : seconds);
    return result;
  };


  function getKeyByValue(object, value) {
    return Object.keys(object).find(key => {
      if(key === 'finished_time') return timeConverter(object[key]).toString().toLowerCase().includes(value.toString().toLowerCase())
      if (typeof object[key] === 'string' && typeof value === 'string') return object[key].toString().toLowerCase().includes(value.toString().toLowerCase());
      if (!isNaN(value) && key === 'id') return object[key].toString().includes(value);
      return false;
    });
  }

  function search(nameKey, myArray){
    return myArray.filter((val) => {
      return getKeyByValue(val, nameKey)
    })
  }

  const searchInTable = (e) => {
    const searchStr = e.target.value;
    const result = search(searchStr, candidate?.candidates);
    setSearchVal(result);
  }

  return (
    <div className="custom-container mt-4">
      <h2 className="text-center theme-primary-blue-text">Candidate Details</h2>
      <div className="form-group row">
        <label for="staticEmail" className="col-sm-2 col-form-label">Search</label>
        <div className="col-sm-10">
          <input type="text" className="form-control" id="staticEmail" onChange={searchInTable} placeholder="Search" />
        </div>
      </div>
      <DataGrid
        className="mt-4 table bg-white"
        rows={searchVal === "" ? candidate?.candidates ? candidate?.candidates: []: searchVal}
        components={{
          Toolbar: GridToolbar,
        }}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5, 10, 20, 100]}
        autoHeight={true}
        autoPageSize={true}
        isRowSelectable={false}
        onRowClick={(key) => {
          history.push(
            "/admin-home/" + position_id + "?candidate_id=" + key.row.id
          );
        }}
        disableExtendRowFullWidth={false}
        density={'comfortable'}
      />

      <ToastContainer />
    </div>
  );
};

export default Index;
