import React, { useEffect } from 'react'
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { SpinnerCircular } from 'spinners-react';
// Schema
import validationEducation from './validation/validation_education';
// API Calls
import { useMutation } from 'react-query';
import { candidateEducationDetail } from '../../../../API_CALLS/Sign-up';
// Store
import { useAtom } from 'jotai';
import { sign_up_pointer } from '../../../../../Store/Signup';

const EducationDetails = () => {
  const formOptions = { resolver: yupResolver(validationEducation) };
  const {control, handleSubmit, formState: { errors }} = useForm(formOptions);
  const {fields, append, remove} = useFieldArray({
    control,
    name: 'education',
  });
  const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
  // API
  const candidateEducationDetail_API = useMutation(candidateEducationDetail);
  const [pointer, setPointer] = useAtom(sign_up_pointer);
  const getYears = () => {
    let arr = [];
    for (let index = new Date().getFullYear(); index >= 1950; index--) {
      arr.push(index);
    }
    return arr;
  }

  const createDateFormat = (year, month) => {
    return month.toString().length === 1 ? year  + "-0" + month + "-01": year  + "-" + month + "-01"; 
  }

  useEffect(() => {
    append({ institute: '', from_month: '', to_date: '', degree: '' })
  }, [])


  return (
    !candidateEducationDetail_API.isLoading ?
    <>
      <div className='container mt-4'>
        <div className="row justify-content-center">
          <form className="col-md-8 mt-5" onSubmit={handleSubmit((data) => {
            const fixDate = data.education.map((education) => {
              education.from_date = createDateFormat(education.from_year, education.from_month)
              education.to_date = createDateFormat(education.to_year, education.to_month)
              return education;
            })
            candidateEducationDetail_API.mutateAsync({education: fixDate}).then(() => {
              setPointer(pointer + 1);
            }).catch((e) => {
              console.log("Error: ", e)
            })
            })}>
            {fields.map((item, index) => {
              return (
              <div key={item.id}>
                <div className="row mt-2">
              <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
              Institute
              </label>
              <div className="col-sm-10">
                <Controller 
                  name={`education.${index}.institute`}
                  control={control}
                  defaultValue={item.institute}
                  render={({field}) =>  <input type="text" className={"form-control " + (errors.education?.[index]?.institute && "border-danger")} {...field} />}
                />
                <span className='text-danger'>
                  {errors.education?.[index]?.institute?.message}
                </span>
              </div>
            </div>
            <div className="row mt-2">
              <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
              Batch
              </label>
              <div className="col-sm-10">
                From
                <div className="row">
                  <div className="col-md-6">
                    <Controller 
                    name={`education.${index}.from_month`}
                    control={control}
                    defaultValue={item.from_month}
                    render={({field}) =>  <select className={"form-select " + (errors.education?.[index]?.from_month && "border-danger")} {...field}>
                      <option value="">Select Month</option>
                      {month.map((month, index) => <option value={index + 1}>{month}</option>)}
                    </select>}
                    />
                    <span className='text-danger'>
                      {errors.education?.[index]?.from_month?.message}
                    </span>
                  </div>
                  <div className="col-md-6">
                    <Controller 
                    name={`education.${index}.from_year`}
                    control={control}
                    defaultValue={item.from_year}
                    render={({field}) =>  <select className={"form-select " + (errors.education?.[index]?.from_year && "border-danger")} {...field}>
                      <option value="">Select Year</option>
                      {getYears().map((year, index) => <option key={index} value={year}>{year}</option>)}
                    </select>}
                    />
                    <span className='text-danger'>
                      {errors.education?.[index]?.from_year?.message}
                    </span>
                  </div>
                </div>
                <br />
                To
                <div className="row">
                  <div className="col-md-6">
                    <Controller 
                    name={`education.${index}.to_month`}
                    control={control}
                    defaultValue={item.to_month}
                    render={({field}) =>  <select className={"form-select " + (errors.education?.[index]?.to_month && "border-danger")} {...field}>
                      <option value="">Select Month</option>
                      {month.map((month, index) => <option value={index + 1}>{month}</option>)}
                    </select>}
                    />
                    <span className='text-danger'>
                      {errors.education?.[index]?.to_month?.message}
                    </span>
                  </div>
                  <div className="col-md-6">
                    <Controller 
                    name={`education.${index}.to_year`}
                    control={control}
                    defaultValue={item.to_year}
                    render={({field}) =>  <select className={"form-select " + (errors.education?.[index]?.to_year && "border-danger")} {...field}>
                      <option value="">Select Year</option>
                      {getYears().map((year, index) => <option key={index} value={year}>{year}</option>)}
                    </select>}
                    />
                    <span className='text-danger'>
                      {errors.education?.[index]?.to_year?.message}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
              Degree
              </label>
              <div className="col-sm-10">
                <Controller 
                  name={`education.${index}.degree`}
                  control={control}
                  defaultValue={item.degree}
                  render={({field}) =>  <input type="text" className={"form-control " + (errors.education?.[index]?.degree && "border-danger")} {...field} />}
                />
                <span className='text-danger'>
                  {errors.education?.[index]?.degree?.message}
                </span>
              </div>
            </div>
            <div className="text-end mt-4">
              {fields.length === index + 1 ? <span className="btn btn-dark" onClick={() => append({ institute: '', from_date: '', to_date: '', degree: '' })}>Add another degree</span>: null }
              &nbsp;&nbsp;&nbsp;
              {fields.length !== 1 ? <button className="btn btn-danger" onClick={() => remove(index)}>Delete</button>: null}
            </div>
              </div>
              )
            })}
            <div className="text-center mt-4 pb-4">
              &nbsp;&nbsp;&nbsp;
              <button className="btn btn-success">Next</button>
            </div>
          </form>
        </div>
      </div>
    </>:<div className="text-center">
      <SpinnerCircular size={50} thickness={100} speed={100} color="#36ad47" secondaryColor="rgba(0, 0, 0, 0.44)" />
    </div> 
  )
}

export default EducationDetails