import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import Navbar from '../../../Parts/Navbar'
import DetailsHeader from './Steps/DetailsHeader'
// API Calls
import { useMutation } from 'react-query';
import { dataFilled } from '../../../API_CALLS/Sign-up';
// Store
import { useAtom } from 'jotai';
import { sign_up_pointer, skip_experience } from '../../../../Store/Signup';
// Component
import PersonalDetail from './StepComp/PersonalDetails';
import EducationDetails from './StepComp/EducationDetails';
import Portfolio from './StepComp/Portfolio'
import ProfessionalDetails from './StepComp/ProfessionalDetails';
import WorkExperience from './StepComp/WorkExperienceDetails';

const TestComp = ({ userDetail }) => {
  const history = useHistory();
  const dataFilled_API = useMutation(dataFilled);
  const [pointer, setPointer] = useAtom(sign_up_pointer);
  const [skip] = useAtom(skip_experience);
  useEffect(() => {
    dataFilled_API.mutateAsync().then((res) => {
      if (res.personal && res.professional && res.education && res.portfolio) {
        history.push("/job-opening")
      }
    })
  }, [pointer])

  const userState = (data) => {
    if(!data.personal) return {comp: <PersonalDetail data={data} userDetail={userDetail} />, active: 0};
    if(!data.professional) return {comp: <ProfessionalDetails />, active: 1};
    if(!data.education) return {comp: <EducationDetails />, active: 2};
    if(!data.work_experience && !skip) return {comp: <WorkExperience />, active: 3};
    if(!data.portfolio) return {comp: <Portfolio />, active: 4};
    return { comp: null, active: null }
  }

  return (
    <>
      { dataFilled_API.data ? 
      <>
        <DetailsHeader active={userState(dataFilled_API.data).active} data={dataFilled_API.data} />
        {userState(dataFilled_API.data).comp}
      </>
      : null }
    </>
  )
}

export default TestComp;


// const a = {personal: 0, professional: 1, work_experience: 2, education: 3, portfolio: 4}
// const pointer = 2;