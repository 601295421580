import React, { useEffect } from "react";
import "./Footer.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import { useMutation } from "react-query";
import { submitAnswer } from "../../API_CALLS/Users";
import { useAtom } from "jotai";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import {
  currentQuesstion,
  questions,
  intervalStore,
  chunkStore,
} from "../../../Store/Users";
import { useState } from "react";

const styles = {
  fontFamily: "sans-serif",
  textAlign: "center",
};

const Footer = ({
  question_sno,
  candidate_job_id,
  total_timer,
  question_timer,
  required,
  submitAnswer_API
}) => {
  const history = useHistory();
  // Recorder
  const [resetInterval] = useAtom(intervalStore);
  const [question] = useAtom(questions);
  const [chunk, setChunk] = useAtom(chunkStore);
  const [currQue, setCurQue] = useAtom(currentQuesstion);
  const { mutateAsync } = useMutation(submitAnswer, { retry: false });

  // Error Handler
  const [queTimeout, setQueTimeout] = useState(null);
  const [isNetwork, setIsNetwork] = useState(false);
  const [networkDownData, setNetworkDownData] = useState({});

  useEffect(() => {
    // Question Timer
    clearInterval(resetInterval);
    questionTimer();
  }, [question_timer]);

  const questionTimer = () => {
    if (question_timer !== undefined) {
      setQueTimeout(
        setTimeout(async () => {
          if (
            window?.mediaRecorder?.state === undefined ||
            window?.mediaRecorder?.state === "inactive"
          ) {
            const res = await skipAnswers(
              question_sno,
              candidate_job_id,
              submitAnswer_API.mutateAsync
            );
            if (res?.success) {
              setCurQue(currQue + 1);
            }
            if (res?.last) {
              history.push("/success");
            }
          } else {
            await nextQuestion(false);
          }
        }, question_timer * 1000)
      );
    }
  };

  const submitAnswers = async (ques_id, job_id, audio_answer, mutateAsync) => {
    try {
      const res = await mutateAsync({
        question_sno: ques_id,
        candidate_job_id: job_id,
        skipped: false,
        audio_answer: audio_answer.audio,
        answer_time: audio_answer.duration,
      });

      if (res.status === "ok") {
        toastFunc(true, "Answer Submitted Successfully");
      }
      if (res.last) {
        toastFunc(true, "All Answers have been submitted successfully");
        return { success: false, last: true };
      } else {
        if (res.status === "ok") {
          return { success: true, last: false };
        }
      }
    } catch (e) {
      if (e.message === "Network Error") {
        setNetworkDownData({ ques_id, job_id, audio_answer });
        setIsNetwork(true);
      }
      if (
        e?.response?.data?.error ===
        "UNIQUE constraint failed: hiring_audioanswer.question_for_candidate_id"
      ) {
        toastFunc(false, e.response.data.error);
      }
      if (e.response?.status === 400) {
        toastFunc(false, e.response.data);
      }

      if (e.response === undefined) {
        toastFunc(false, "Please Record your answer and submit.");
      }
    }
  };

  const retryAnswer = async () => {
    setIsNetwork(false);
    const { ques_id, job_id, audio_answer } = networkDownData;
    const res = await submitAnswers(ques_id, job_id, audio_answer, submitAnswer_API.mutateAsync);
  };

  useEffect(() => {
    // Total Timer
    setTimeout(async () => {
      let currentQueSubmitted = true;
      for (var i = currQue; i < question.length; i++) {
        if (currQue <= question[i].question_sno) {
          if (currentQueSubmitted) {
            currentQueSubmitted = false;
            if (window?.mediaRecorder?.state === undefined) {
              await skipQuestion(true);
            } else {
              await nextQuestion(true);
            }
          } else {
            const res = await skipAnswers(
              question[i].question_sno,
              question[i].candidate_job_id,
              submitAnswer_API.mutateAsync,
              true
            );
            if (res?.last) {
              history.push("/success");
            }
          }
        }
      }
    }, total_timer * 1000);
  }, [question_timer]);

  const nextQuestion = async (isTimer) => {
    try {
      if (!isTimer && chunk.length === 0) {
        return toastFunc(false, "Please Record your answer and submit.");
      }
      if (window?.mediaRecorder?.state === undefined) {
        return toastFunc(false, "Please Record And Submit answer");
      }
      if (window?.mediaRecorder?.state !== undefined) {
        window.mediaStream.getTracks().forEach((track) => {
          track.stop();
        });
      }
      window.mediaRecorder.onstop = async (e) => {
        const blob = new Blob(chunk, {
          type: "audio/mpeg-3",
        });
        const res = await submitAnswers(
          question_sno,
          candidate_job_id,
          { audio: blob, duration: chunk.length },
          submitAnswer_API.mutateAsync
        );
        setChunk([]);
        if (!isTimer) {
          if (res?.last) {
            return history.push("/success");
          }
          if (res?.success) {
            setCurQue(currQue + 1);
          }
        }
        clearInterval(resetInterval);
        clearTimeout(queTimeout);
      };
    } catch (err) {
      console.log(err.response);
    }
  };

  const skipQuestion = async (isTimer) => {
    try {
      clearTimeout(queTimeout);
      clearInterval(resetInterval);
      if (window?.mediaRecorder?.state !== undefined) {
        window.mediaStream.getTracks().forEach((track) => {
          track.stop();
        });
      }
      const res = await skipAnswers(
        question_sno,
        candidate_job_id,
        submitAnswer_API.mutateAsync
      );
      setChunk([]);
      if (!isTimer) {
        if (currQue === question.length) {
          history.push("/success");
        }

        if (res?.last) {
          history.push("/success");
        }
        if (res?.success) {
          setCurQue(currQue + 1);
        }
      } else {
        if (res?.last) {
          history.push("/success");
        }
      }
    } catch (error) {
      console.log(error?.response?.error);
    }
  };

  const skipAnswers = async (ques_id, job_id, mutateAsync) => {
    try {
      const res = await mutateAsync({
        question_sno: ques_id,
        candidate_job_id: job_id,
        skipped: true,
      });

      if (res.status === "ok") {
        toastFunc(true, "Answer Skipped Successfully");
      }
      if (res.last) {
        toastFunc(true, "All Answers have been submitted successfully");
        return { success: false, last: true };
      } else {
        if (res.status === "ok") {
          return { success: true, last: false };
        }
      }
    } catch (error) {
      if (error.message === "Network Error") {
      }
      if (error.response.status === 400) {
        if (error.response.data?.error === "Already answered") {
          setCurQue(currQue + 1);
        }
      }
      if (error) {
        toastFunc(false, "Connection Failed");
      }
    }
  };

  return (
    <div className="footer-btn">
      <Modal
        open={isNetwork}
        onClose={() => {
          setIsNetwork(false);
        }}
      >
        <h6 className="mt-4">
          Network Failed Click on Retry to submit your answer
        </h6>
        <div className="text-center">
          <button className="btn btn-primary" onClick={retryAnswer}>
            Retry
          </button>
        </div>
      </Modal>
      {!required ? (
        <button
          onClick={() => {
            return skipQuestion(false);
          }}
          className="btn btn-danger"
        >
          Skip <span>Question</span>
        </button>
      ) : null}

      <button
        onClick={() => {
          return nextQuestion(false);
        }}
        className="btn btn-primary"
        disabled={submitAnswer_API.isLoading}
      >
        Upload and show the next question
      </button>
      <ToastContainer position="bottom-center" />
    </div>
  );
};

const toastFunc = (success, msg) => {
  if (success) {
    return toast.success(msg, {
      position: "bottom-center",
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }
  return toast.error(msg, {
    position: "bottom-center",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

export { Footer };
