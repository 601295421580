import React, { useEffect } from "react";
import "./Hero.css";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import {
  getJobList,
  getShortListed,
  getCompanies,
  getQuestions,
} from "../../../../../API_CALLS/Admin";
import { useAtom } from "jotai";
import { toast, ToastContainer } from "react-toastify";
import {
  jobList as job,
  shortListed,
  listCompanies,
  adminCompany,
  adminQuestion,
} from "../../../../../../Store/Users";
import { useHistory } from "react-router-dom";

const Hero = ( { candidate, candidateIds } ) => {
  const history = useHistory();
  const { position_id } = useParams();
  const { mutateAsync: jobMutate, isSuccess } = useMutation(getJobList);
  const { mutateAsync: companyMutate } = useMutation(getCompanies);
  const [, setCompanies] = useAtom(listCompanies);
  const [shortListedData, setShortListed] = useAtom(shortListed);
  const shortlistedOnly = useMutation(getShortListed);
  const [jobList, setJobList] = useAtom(job);
  const [company] = useAtom(adminCompany);
  const queFetch = useMutation(getQuestions);
  const [, setQuestions] = useAtom(adminQuestion);
  
  useEffect(() => {
    let isMounted = true;
    lists();
    listCompaniesFunc();
    return () => {
      isMounted = false;
    };
  }, []);

  const lists = async () => {
    const response = await jobMutate();
    setJobList(response);
  };

  const listCompaniesFunc = async () => {
    const response = await companyMutate();
    setCompanies(response);
  };

  const handleSelect = async (e) => {
    const selectedPosition = e.target.value;
    if (selectedPosition === "") {
      history.push("/admin-home");
    }
    history.push("/admin-home/" + selectedPosition);
  };

  const viewShortListed = async (selectedPosition) => {
    if (selectedPosition === "") {
      return toast.error("Please select a job position", {
        position: "bottom-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
    const response = await shortlistedOnly.mutateAsync(selectedPosition);
    setShortListed(response);
  };

  const continueEval = () => {
    if (candidateIds.length) {
      history.push("/admin-home/" + position_id + "?candidate_id=" + candidateIds[0]);
    } else {
      return toast.error("No candidates to shortlist", {
        position: "bottom-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  return (
    <div className="admin-hero-container custom-container">
      <h2 className="text-center">Admin</h2>
      <div className="row mb-4 justify-content-around">
        <button
          type="button"
          className="btn btn-primary col-2"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        >
          Add Invite
        </button>
        <button
          type="button"
          className="btn btn-primary col-2"
          data-bs-toggle="modal"
          data-bs-target="#add-question"
        >
          Add Question
        </button>
        <button
          type="button"
          className="btn btn-primary col-2"
          data-bs-toggle="modal"
          data-bs-target="#add-new-job"
          onClick={async () => {
            if (company !== "") {
              const res = await queFetch.mutateAsync(company);
              setQuestions(res);
            }
          }}
        >
          Add Job
        </button>
      </div>
      <div className="card">
        <p>Evaluations</p>
        <div className="row">
          <ul className="col-md-4">
            {jobList.length && isSuccess ? <select
              className="form-select"
              onChange={handleSelect}
              value={position_id}
            >
              <option value="">
                Select a Position
              </option>
              {jobList.map(({ id, title }, index) => {
                return (
                  <option key={index} value={id}>
                    {title}
                  </option>
                );
              })}
            </select>: null}
          </ul>
        </div>
        {position_id ? (
          <div>
            <p>Summary</p>
            {candidate ? (
              <div className="row">
                <ul className="list-group-position col-md-5">
                  <li className="list-group-item row justify-content-around">
                    <span className="col-3">Position:</span>
                    <span className="col-3">{candidate.position}</span>
                  </li>
                  <li className="list-group-item row justify-content-around">
                    <span className="col-3">Total submission:</span>
                    <span className="col-3">{candidate.total_submission}</span>
                  </li>
                  <li className="list-group-item row justify-content-around">
                    <span className="col-3">Pending Evaluation</span>
                    <span className="col-3">{candidate.pending}</span>
                  </li>
                  <li className="list-group-item row justify-content-around">
                    <span className="col-3">Evaluated</span>
                    <span className="col-3">
                      ( {candidate.rejected} Rejected, {candidate.shortlisted}
                      Shortlisted )
                    </span>
                  </li>
                </ul>
              </div>
            ) : null}

            <section className="admin-home-btn">
              <button className="btn btn-primary" onClick={continueEval}>
                Continue Evaluation
              </button>
              <button
                className="btn btn-primary"
                onClick={() => {
                  if (!shortListedData.length) {
                    return viewShortListed(position_id);
                  } 
                  return setShortListed([]);
                }}
              >
                {!shortListedData.length ? "View Shortlisted Candidate": "View All"}
              </button>
            </section>
          </div>
        ) : null}
      </div>
      <ToastContainer position="bottom-center" />
    </div>
  );
};

export default Hero;
