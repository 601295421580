import * as Yup from 'yup';

Yup.addMethod(Yup.string, 'stripEmptyString', function () {
    return this.transform((value) => (value === '' ? undefined : value));
});

Yup.addMethod(Yup.mixed, 'noImage', function () {
    return this.transform((value) => (value.length === 0 ? undefined : value));
});

const validationWork = Yup.object().shape({
  work_experience: Yup.array()
      .of(
        Yup.object().shape({
          designation: Yup.string().required("Designation is required"),
          organization: Yup.string().required("Organization is required"),
          current: Yup.boolean().notRequired(),
          from_month: Yup.string().required("From Month is required"),
          from_year: Yup.string().required("From Year is required"),
          to_month: Yup.string().when("current", {
            is: true,
            then: Yup.string().typeError('You must specify a number').notRequired(),
            otherwise: Yup.string().typeError('To date is required').required("To Month is required")
          }),
          to_year: Yup.string().when("current", {
            is: true,
            then: Yup.string().typeError('You must specify a number').notRequired(),
            otherwise: Yup.string().typeError('To date is required').required("To Year is required")
          })
        })  
      )
});

export default validationWork;