import React, { useState, useEffect } from "react";
import MicRecorder from "mic-recorder-to-mp3";
import Question from "../Question";
import "./qanda.css";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const Index = () => {
  const [isBlocked, setIsBlocked] = useState(false);
  const [content, setContent] = useState([
    { id: 1, question: "Popoyy the sailor man", duration: 5 },
    {
      id: 2,
      question: "Hi Everyone",
      duration: 1,
    },
  ]);
  const [currentQue, setCurrentQue] = useState(0);
  const [error, setError] = useState(false);

  useEffect(() => {
    const customFields = content.map((msg, index) => {
      msg.setIsRecording = false;
      msg.setBlob = "";
      msg.id = index;
      return msg;
    });
    setContent(customFields);
  }, []);

  useEffect(() => {
    navigator.permissions
      .query({ name: "microphone" })
      .then(function (permissionStatus) {
        if (permissionStatus.state === "granted") {
          setIsBlocked(false);
        }

        if (permissionStatus.state === "denied") {
          setIsBlocked(true);
        }
      });
  }, []);

  // Actual Function that thouches the state
  const changeState = (id, blobURL) => {
    const newState = content.map((msg) => {
      if (msg.id === id) {
        if (blobURL) {
          msg.setBlob = blobURL;
        }
        if (blobURL === "delete") {
          msg.setBlob = "";
          msg.setIsRecording = true;
        }
        msg.setIsRecording = !msg.setIsRecording;
      }
      return msg;
    });
    setContent(newState);
  };

  // Start the recoding with its duration
  const start = (id, blob, duration) => {
    if (isBlocked) {
      console.log("Permission Denied");
    } else {
      Mp3Recorder.start()
        .then((data) => {
          changeState(id);
          setTimeout(() => {
            stop(id);
          }, duration * 1000);
        })
        .catch((e) => console.error(e));
    }
  };

  // Stops the recording
  const stop = (id) => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        // const file = new File(buffer, `${uuid()}-${id}.mp3`, {
        //   type: blob.type,
        //   lastModified: Date.now(),
        // });
        // console.log(file);
        const blobURL = URL.createObjectURL(blob);
        changeState(id, blobURL);
      })
      .catch((e) => console.log(e));
  };

  // Deletes the current recorded audio
  const deleteAudio = (id) => {
    changeState(id, "delete");
  };

  // Moves to next question
  const nextQue = () => {
    // Check the user is submitting empty recording
    if (content[currentQue].setBlob === "") {
      return setError(true);
    } else {
      setError(false);
    }
    // Increment the question by 1
    if (content.length - 1 > currentQue) {
      setCurrentQue(currentQue + 1);
    }
  };

  // Submit Recording
  const submitRecording = () => {
    // Check the user is submitting empty recording
    if (content[currentQue].setBlob === "") {
      return setError(true);
    } else {
      setError(false);
    }
    console.log(content);
  };

  return (
    <div className="container">
      <div className="card other-bg ">
        <h5 className="text-center">
          Please answer the following questions{" "}
          <span style={{ fontSize: "0.7rem" }}>
            (Record answer and submit )
          </span>
        </h5>
        <h6 id="que-counter">
          Question:{" "}
          <span className="theme-purple-text">
            {" "}
            {`${currentQue + 1} / ${content.length}`}
          </span>
        </h6>
        <div className="row justify-content-center">
          {
            <Question
              key={content[currentQue].id}
              question={content[currentQue].question}
              start={start}
              stop={stop}
              deleteAudio={deleteAudio}
              blob={content[currentQue].setBlob}
              recordingStatus={content[currentQue].setIsRecording}
              id={content[currentQue].id}
              duration={content[currentQue].duration}
            />
          }
          <div className="text-center text-danger">
            {error ? <p>Answer current question to move to the next</p> : null}
          </div>
          <div className="text-center">
            {currentQue === content.length - 1 ? (
              <button
                className="btn btn-primary mt-4"
                style={{ width: "6rem" }}
                onClick={submitRecording}
              >
                Submit
              </button>
            ) : (
              <button
                className="btn btn-primary mt-4"
                style={{ width: "6rem" }}
                onClick={nextQue}
              >
                Next
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
