import React, { useState, useEffect } from "react";
import { useMutation } from "react-query";
import { getQuestions, addJob } from "../../../../../API_CALLS/Admin";
import { useAtom } from "jotai";
import {
  listCompanies,
  adminCompany,
  adminQuestion,
} from "../../../../../../Store/Users";
import { ToastContainer } from "react-toastify";
import Toast from "../../../../../Utils/toast";

const Job = () => {
  const [company, setCompany] = useAtom(adminCompany);
  const [companies] = useAtom(listCompanies);

  const { mutateAsync } = useMutation(addJob);
  const [formValues, setFormValues] = useState([
    { batch_id: "", required: false },
  ]);

  const queFetch = useMutation(getQuestions);
  const [questions, setQuestions] = useAtom(adminQuestion);

  const [job_title, setJobTitle] = useState("");
  const [notes, setNotes] = useState("");
  const [extraTime, setExtraTime] = useState("");

  useEffect(() => {
    if (company.length !== 0) {
      getQuestionList(company);
    }
  }, [company]);

  const selectCompany = async (e) => {
    if (e.target.value === "") {
      setCompany(e.target.value);
      setQuestions([]);
      return;
    }
    setCompany(e.target.value);
  };

  const getQuestionList = async (company) => {
    const res = await queFetch.mutateAsync(company);
    setQuestions(res);
  };

  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    if (e.target.name === "required") {
      newFormValues[i][e.target.name] = e.target.checked;
      setFormValues(newFormValues);
      return;
    }
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  let addFormFields = () => {
    setFormValues([...formValues, { batch_id: "", required: false }]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  let handleSubmit = async (event) => {
    event.preventDefault();
    let val = "";
    let prev = [];

    formValues.forEach(({ batch_id }) => {
      const batch = parseInt(batch_id?.split("&")[0]);

      if (prev.includes(batch)) {
        const filter = questions.filter((question) => {
          return question?.id === batch;
        });
        val = `Batch: ${filter[0]?.batch_name} selected multiple times`;
        return;
      }
      prev.push(batch);
    });

    if (val !== "") {
      Toast(false, val);
      return;
    }

    const newForm = formValues.map(({ batch_id, required }) => {
      const [batch, order] = batch_id.split("&");
      return { batch_id: parseInt(batch), order: parseInt(order), required };
    });
    const data = {
      company: parseInt(company),
      notes: notes,
      extra_time: parseInt(extraTime),
      job_title: job_title,
      batches: newForm,
    };
    const res = await mutateAsync(data);
    if (res.status === "ok") {
      Toast(true, "Job Successfully Added");
      setJobTitle("");
      setExtraTime("");
      setNotes("");
      setFormValues([{ batch: "", required: false }]);
    }
  };

  return (
    <div>
      <div
        className="modal fade"
        id="add-new-job"
        tabIndex={-3}
        aria-labelledby="exampleModalLabel2"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel2">
                Add Job
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <h4 className="text-center">Select Company</h4>
              <label htmlFor="company">Company</label>
              <select
                className="form-select mb-3"
                aria-label=".form-select-lg example"
                onChange={selectCompany}
              >
                <option value="" selected>
                  Select Company
                </option>
                {companies.length !== 0
                  ? companies?.map((company, key) => {
                      return (
                        <option key={key} value={company.id}>
                          {company.company_name}
                        </option>
                      );
                    })
                  : null}
              </select>
              {/* FORM */}
              {company !== "" ? (
                <form onSubmit={handleSubmit}>
                  <hr className="my-4" />
                  <h4 className="text-center">Job Details </h4>
                  <div className="container">
                    <div className="row">
                      <div className="col-6">
                        <label htmlFor="job_title">Job Title</label>
                        <input
                          className="form-control form-control"
                          type="text"
                          placeholder="For Ex. DB Admin"
                          aria-label=".form-control-lg example"
                          name="job_title"
                          onChange={(e) => {
                            setJobTitle(e.target.value);
                          }}
                          value={job_title}
                          required
                        />
                      </div>
                      <div className="col-6">
                        <label htmlFor="extra_time">Extra Time</label>
                        <input
                          className="form-control form-control"
                          type="number"
                          placeholder="For Ex. 20"
                          aria-label=".form-control-lg example"
                          name="extra_time"
                          min="0"
                          onChange={(e) => {
                            setExtraTime(e.target.value);
                          }}
                          value={extraTime}
                          required
                        />
                      </div>
                      <div>
                        <label htmlFor="notes">Notes</label>
                        <textarea
                          className="form-control form-control-lg"
                          type="text"
                          aria-label=".form-control-lg example"
                          name="notes"
                          value={notes}
                          required
                          onChange={(e) => {
                            setNotes(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <hr className="my-4" />
                  <h4 className="text-center">Question Group</h4>
                  <div className="row justify-content-center mb-2">
                    <div className="col-1">No</div>

                    <div className="col-8">List Of Batches</div>
                    <div className="col-1">Required</div>
                    <div className="col-1">Delete</div>
                  </div>
                  {formValues.map((element, index) => {
                    return (
                      <div key={index}>
                        <div className="row justify-content-center">
                          <div className="col-1 align-items-center">
                            {index + 1}
                          </div>
                          <div className="col-8">
                            <select
                              className="form-select mb-3"
                              aria-label=".form-select-lg example"
                              name="batch_id"
                              value={element.batch_id || ""}
                              onChange={(e) => {
                                handleChange(index, e);
                              }}
                              required
                            >
                              <option value="" selected>
                                Select Batch
                              </option>
                              {questions.length !== 0
                                ? questions.map((question, key) => {
                                    const { id, batch_name } = question;
                                    return (
                                      <option
                                        value={id + "&" + (index + 1)}
                                        key={key}
                                      >
                                        {batch_name}
                                      </option>
                                    );
                                  })
                                : null}
                            </select>
                          </div>
                          <div className="col-1">
                            <div className="form-check form-switch mt-2">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                defaultChecked={element.required}
                                name="required"
                                onChange={(e) => {
                                  handleChange(index, e);
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexSwitchCheckDefault"
                              ></label>
                            </div>
                          </div>
                          {index ? (
                            <div className="col-1 text-danger align-self-center hover">
                              <i
                                className="fas fa-trash-alt h2"
                                onClick={() => removeFormFields(index)}
                              ></i>
                            </div>
                          ) : (
                            <div className="col-1 text-danger align-self-center hover">
                              <i className="fas fa-trash-alt h2"></i>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}

                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => addFormFields()}
                    >
                      Add Batch
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Job;
