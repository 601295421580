import { atom } from "jotai";
// Gets User Info name, email

const isLoggedIn = atom({ loggedIn: false, role: "user" });
const jobRole = atom([]);
const currentRole = atom(null);
const selectedJob = atom(null);
const totalTimer = atom(0);
const onlyTotalTime = atom(0);
const currentRecording = atom(null);
const currentQuesstion = atom(0);
const intervalStore = atom(null);
const totalInterval = atom(null);
const chunkStore = atom([]);
const chunkData = atom(null);
const questions = atom([]);
const queTimerStore = atom(0);
const skipStatus = atom(false);
const sampleURL = atom("");

// Admin
const jobList = atom([]);
const candidates = atom(null);
const answer = atom(null);
const counterCandidate = atom(0);
const job_id = atom("");
const shortListed = atom([]);
const listCompanies = atom([]);
const adminCompany = atom("");
const adminQuestion = atom([]);
const filteredCandidate = atom([]);
const showShortListed = atom(false);
const candidateIDS_store = atom([]);
const custom_notes = atom([]);

export {
  isLoggedIn,
  jobRole,
  selectedJob,
  totalTimer,
  sampleURL,
  currentRecording,
  currentQuesstion,
  jobList,
  candidates,
  answer,
  counterCandidate,
  intervalStore,
  chunkStore,
  questions,
  queTimerStore,
  skipStatus,
  job_id,
  shortListed,
  totalInterval,
  onlyTotalTime,
  listCompanies,
  adminCompany,
  adminQuestion,
  filteredCandidate,
  currentRole,
  showShortListed,
  chunkData,
  candidateIDS_store,
  custom_notes
};
