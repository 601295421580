import * as Yup from 'yup';

Yup.addMethod(Yup.string, 'stripEmptyString', function () {
    return this.transform((value) => (value === '' ? undefined : value));
});

Yup.addMethod(Yup.mixed, 'noImage', function () {
    return this.transform((value) => (value.length === 0 ? undefined : value));
});

const validationEducation = Yup.object().shape({
  education: Yup.array()
      .of(
        Yup.object().shape({
          institute: Yup.string().required("Enter Institute Name"),
          degree: Yup.string().required("Degree is required"),
          from_month: Yup.string().required("From Month is required"),
          from_year: Yup.string().required("From Year is required"),
          to_month: Yup.string().required("To Month is required"),
          to_year: Yup.string().required("To Year is required")
        })  
      )
});

export default validationEducation;