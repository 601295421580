import React, { useState } from "react";
import { useMutation } from "react-query";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import { addInvite } from "../../../../../API_CALLS/Admin";
import { jobList } from "../../../../../../Store/Users";
import { useAtom } from "jotai";
import toastIt from "../../../../../Utils/toast";

const Modal = () => {
  const [emails, setEmails] = useState([]);
  const [job] = useAtom(jobList);
  const { mutateAsync } = useMutation(addInvite);
  const [selectedJob, setSelectedJob] = useState("");
  const submitEmails = async () => {
    try {
      if (selectedJob === "") {
        return toastIt(false, "Please select a job position");
      }
      if (emails.length === 0) {
        return toastIt(false, "Please Enter a email");
      }
      const emailAsArrofObj = emails.map((email) => {
        return { email: email };
      });

      const res = await mutateAsync({
        job_id: parseInt(selectedJob),
        emails: emailAsArrofObj,
      });
      if (res === "ok") {
        setEmails([]);
        return toastIt(true, "Invite Added Successfully");
      }
      return res;
    } catch (e) {
      if (e) {
        return toastIt(false, "Cannot Add Email");
      }
    }
  };

  return (
    <div>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Invite
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <select
                className="form-select mb-4"
                onChange={(e) => {
                  setSelectedJob(e.target.value);
                }}
                name=""
                id=""
              >
                <option value="" defaultValue>
                  Select a Position
                </option>
                {job.map(({ id, title }, index) => {
                  return (
                    <option key={index} value={id}>
                      {title}
                    </option>
                  );
                })}
              </select>
              {selectedJob === "" ? null : (
                <div>
                  <ReactMultiEmail
                    placeholder="Emails: (Add multiple emails by comma separated values)"
                    style={{ width: "100%" }}
                    emails={emails}
                    onChange={(_emails) => {
                      setEmails([..._emails]);
                    }}
                    validateEmail={(email) => {
                      return isEmail(email);
                    }}
                    getLabel={(email, index, removeEmail) => {
                      return (
                        <div data-tag key={index}>
                          {email}
                          <span
                            data-tag-handle
                            onClick={() => removeEmail(index)}
                          >
                            ×
                          </span>
                        </div>
                      );
                    }}
                  />
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={submitEmails}
                    >
                      Send E-Mails
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
