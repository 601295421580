import React from "react";
import Navbar from "../../../Parts/Navbar";
import Openings from "../../../Parts/Openings";

const index = () => {
  return (
    <>
      <Openings />
    </>
  );
};

export default index;
