import axios from "axios";
import { v4 as uuidv4 } from "uuid";

axios.defaults.withCredentials = true;
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";
// Get User Details after user logged in
const getUserDetails = async () => {
  const { data } = await axios.get(
    process.env.REACT_APP_ROOT_URL + "/hiring/viewuser",
    {
      withCredentials: true,
    }
  );
  return data;
};

// Create New User
const uploadForm = async (formData) => {
  const { data } = await axios.post(
    process.env.REACT_APP_ROOT_URL + "/hiring/adduser",
    formData,
    {
      headers: {
        "content-type": "multipart/form-data",
      },
      withCredentials: true,
    }
  );
  return data;
};

// Get List of jobs
const getJobList = async () => {
  const { data } = await axios.get(
    process.env.REACT_APP_ROOT_URL + "/hiring/candidate_jobs",
    { withCredentials: true }
  );
  return data;
};

// Get Question
const getNextQuestion = async ({ question_sno, candidate_job_id }) => {
  const { data } = await axios.post(
    process.env.REACT_APP_ROOT_URL + "/hiring/next_question",
    { question_sno: question_sno, candidate_job_id: candidate_job_id },
    {
      headers: {
        "content-type": "application/json",
      },
      withCredentials: true,
    }
  );
  return data;
};

// Submit Answers
const submitAnswer = async (answer) => {
  const formData = new FormData();
  Object.keys(answer).forEach((key) => {
    if (key === "audio_answer") {
      return formData.append(key, answer[key], `${uuidv4()}.wav`);
    }
    formData.append(key, answer[key]);
  });

  const { data } = await axios.post(
    process.env.REACT_APP_ROOT_URL + "/hiring/add_answer",
    formData,
    {
      headers: {
        "content-type": "multipart/form-data",
      },
      withCredentials: true,
    }
  );
  return data;
};

// Job Selection
const selectJob = async (job_id) => {
  const { data } = await axios.post(
    process.env.REACT_APP_ROOT_URL + "/hiring/start_interview",
    { job_id: job_id },
    {
      headers: {
        "content-type": "application/json",
      },
      withCredentials: true,
    }
  );
  return data;
};

// Check phone number exist already
const checkNumberExist = async (number) => {
  const { data } = await axios.get(
    process.env.REACT_APP_ROOT_URL + `/hiring/checknumber/${number}`,
    { withCredentials: true }
  );
  return data;
};

export {
  getUserDetails,
  uploadForm,
  submitAnswer,
  selectJob,
  getNextQuestion,
  getJobList,
  checkNumberExist,
};
