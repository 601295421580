import React, { useState, useEffect } from "react";
import "./index.css";
import { Document, Page, pdfjs } from "react-pdf";
import ReadMoreReact from 'read-more-react';
import { useHistory } from "react-router-dom";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import Player from "../Player";
import getBlobDuration from "get-blob-duration";
import axios from "axios";
import { useAtom } from "jotai";
import { answer as userAnswer, custom_notes } from "../../../../../Store/Users";
import { toast } from "react-toastify";
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import "@webscopeio/react-textarea-autocomplete/style.css";
// API CALL
import { useMutation } from "react-query";
import { addNote } from "../../../../API_CALLS/Admin";

const Index = () => {
  const history = useHistory();
  const [answer] = useAtom(userAnswer);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [allAudioURL, setAudioURL] = useState([]);
  const [blobDuration, setBlobDuration] = useState(0);
  const addNote_API = useMutation(addNote);
  const [note, setNote] = useState("");
  const [customNote, setCustomNote] = useAtom(custom_notes);

  // Setting Worker
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const preLoadAudio = async (url) => {
    const res = await axios.get(url, { responseType: "blob" });
    setBlobDuration(await getBlobDuration(res.data));
    var audioURL = URL.createObjectURL(res.data);
    return audioURL;
  };

  // Fixing alignment PDF
  function removeTextLayerOffset() {
    const textLayers = document.querySelectorAll(
      ".react-pdf__Page__textContent"
    );
    textLayers.forEach((layer) => {
      const { style } = layer;
      style.top = "0";
      style.left = "0";
      style.transform = "";
    });
  }

  const [windowSize, setWindowSize] = useState();
  // Set Based on Window resize
  useEffect(() => {
    if (answer !== null) {
      answer?.questions?.map(async (data) => {
        try {
          const res = await preLoadAudio(
            process.env.REACT_APP_ROOT_URL + data.answers?.audio_answer
          );
          const arr = [];
          arr.push(...allAudioURL);
          arr.push(res);
          setAudioURL(arr);
        } catch (error) {}
      });
    }
  }, []);

  useEffect(() => {
    setNote(answer?.notes ? answer?.notes: "");
    setCustomNote(answer?.common_notes);
  }, [answer])

  useEffect(() => {
    if (window.innerWidth < 768) {
      setWindowSize(300);
    } else if (window.innerWidth < 1024) {
      setWindowSize(400);
    } else {
      setWindowSize(500);
    }
    window.addEventListener("resize", () => {
      if (window.innerWidth < 768) {
        setWindowSize(300);
      } else {
        setWindowSize(500);
      }
    });
  }, [answer]);

  const nextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const prevPage = () => {
    if (pageNumber !== 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const Item = ({ entity: { name, char } }) => <div>{`${name}: ${char}`}</div>;

  return !answer ? <h2 className="text-center  theme-primary-blue-text">Candidate Not Found!</h2> : (
    <div className="custom-container user-result">
      <div className="card-headline row">
        <h6 className="col-md-9">Evaluation</h6>
        <h6 className="col-md-3">Additional Info</h6>
      </div>
      <div className="row details">
        <h6 className="col-md-7">
          <div className="row mt-4 justify-content-between">
            <div className="col-md-6">
              <div className="card">
                <div className="row position-details text-dark">
                  <div className="row">
                    <p className="col">Position</p>
                    <p className="col">{answer.job?.title}</p>
                  </div>
                  <div className="row">
                    <p className="col">Candidate Name</p>
                    <p className="col">
                      {" "}
                      {answer.candidate.personal?.first_name +
                        " " +
                        answer.candidate.personal?.last_name}
                    </p>
                  </div>
                  <div className="row">
                    <p className="col">Gender</p>
                    <p className="col">{answer.candidate.personal?.gender}</p>
                  </div>
                  <div className="row">
                    <p className="col">Candidate Phone Number</p>
                    <p className="col">{answer.candidate.personal?.number}</p>
                  </div>
                  <div className="row">
                    <p className="col">Candidate Whatsapp Number</p>
                    <p className="col">{answer.candidate.personal?.wa_number}</p>
                  </div>
                  <div className="row">
                    <p className="col">Candidate Email</p>
                    <p className="col">{answer.candidate.personal?.email}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="card theme-primary-blue-text answered-total">
                <h5>
                  Answered {answer?.total_answered} out of{" "}
                  {answer?.total_questions} <br /> Total Duration -{" "}
                  {`0${Math.floor(answer?.total_time / 60) % 60}`.slice(-2)}:
                  {`0${answer?.total_time % 60}`.slice(-2)} min
                </h5>
              </div>
            </div>
          </div>
          <div className="answered-question">
            {answer.questions.map((data, key) => {
              return (
                <div className="card mt-4" key={key}>
                  <div className="card-body">
                    <p>
                      <span className="text-dark">Question {key + 1}</span> -{" "}
                      {data.question?.question_text}
                    </p>
                    {!data.skipped ? (
                      <Player
                        audioURL={
                          process.env.REACT_APP_ROOT_URL +
                          data.answers?.audio_answer
                        }
                        duration={blobDuration}
                      />
                    ) : (
                      <h4 className="text-danger text-center">Unanswered</h4>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </h6>
        <div className="col-md-5 justify-content-center">
          <ProfessionalDetails professional={answer.candidate?.professional} />
          <AdditionalDetails professional={answer.candidate?.professional} education={answer.candidate?.education} experience={answer.candidate.work_experience} portfolio={answer.candidate.portfolio} />
          <div className="text-end">
            <a
              className="btn btn-primary mt-4"
              data-bs-toggle="collapse"
              href="#multiCollapseExample1"
              role="button"
              aria-expanded="false"
              aria-controls="multiCollapseExample1"
            >
              Show More/Hide
            </a>
          </div>
          <div>
            <label htmlFor="notes">Notes</label>
            <br />
              <ReactTextareaAutocomplete
              className="form-control"
              loadingComponent={() => <span>Loading</span>}
              style={{
                fontSize: "18px",
                lineHeight: "20px",
                padding: 5
              }}
              containerStyle={{
                marginTop: 20,
                width: 400,
                height: 100,
                margin: "20px auto"
              }}
              trigger={{
                "/": {
                  dataProvider: token => {
                    const val = [];
                    customNote.forEach(({note}, index) => {
                      if (note.toLowerCase().includes(token.toLowerCase())) {
                        val.push({ name: index, char: note })
                      }
                    });
                    return val.slice(0, 10);
                  },
                  component: Item,
                  output: (item, trigger) => item.char
                }
              }}
              onChange={(e) => setNote(e.target.value)}
              value={note}
            />
            <br />
            <button className="btn btn-primary" onClick={() => {
              // if (note === "") {
              //   toast.error("Note cannot be empty", {autoClose: 1500});
              //   return
              // }
              addNote_API.mutateAsync({ note, id: answer.id }).then(() => {
                toast.success("Added successfully", {autoClose: 1500});
              }).catch(() => {
                toast.success("Failed adding note", {autoClose: 1500});
              })
              }}>Add Note</button>
          </div>
        
          <div className="mt-4">
            <h5>Common notes</h5>
            <div>
              {customNote?.map((data, index) => {
                return <div className="form-check" key={index}>
                  <span className="badge bg-secondary hover" onClick={() => {
                    setNote(note + '\n' + data.note);
                  }}>Add</span> &nbsp;
                  <label className="form-check-label" for="flexCheckDefault">
                    {data.note}
                  </label>
                </div>
              })}
            </div>
            <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#add-common-note-modal">Add common note</button>
          </div>
        </div>
        <div className="custom-container pb-4 mt-4">
          <div className="card p-4 text-dark">
            <h5 className="text-center">Resume</h5>
            {/* <a
              href={
                process.env.REACT_APP_ROOT_URL +
                answer.candidate.professional?.resume
              }
              target="_blank"
              rel="noopener noreferrer"
            > */}
            <div className="hover">
              <Document
                file={
                  process.env.REACT_APP_ROOT_URL +
                  answer.candidate.professional?.resume
                }
                onLoadSuccess={onDocumentLoadSuccess}
                styleName=""
              >
                <Page
                  width={windowSize}
                  pageNumber={pageNumber}
                  onLoadSuccess={removeTextLayerOffset}
                  onClick={() => window.open(process.env.REACT_APP_ROOT_URL + answer.candidate.professional?.resume, "_blank")}
                />
              </Document>
              </div>
            {/* </a> */}
            <p>
              Page {pageNumber} of {numPages}
            </p>
            <div className="text-center text-success">
              <i className="fas fa-arrow-circle-left" onClick={prevPage}></i>{" "}
              {"  "}
              <i className="fas fa-arrow-circle-right" onClick={nextPage}></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const EducationDetails = ({ education }) => {
  return (
    <div className="mt-3">
      <strong>Education</strong>
      {education.map((education, index) => {
        return (
          <ul key={index} className="mt-3">
            <li>{education?.degree} from {education?.institute} : {education?.from_date} - {education?.to_date}</li>
          </ul>
        );
      })}
    </div>
  );
};

const WorkExperience = ({ experience }) => {
  return (
    <div className="mt-3">
      <strong>Experience</strong>
      {experience.map((data, index) => {
        return (
          <ul key={index} className="mt-3">
            <li>{data?.designation} at {data?.organization} ({data?.from_date} - {data?.current ? "Now" : data?.to_date})</li>
          </ul>
        );
      })}
    </div>
  );
};

const Portfolio = ({ portfolio }) => {
  return (
    <div className="mt-3">
      <strong>Portfolio</strong>
      {portfolio.map((data, index) => {
        return (
          <ul key={index} className="mt-3">
            <li>{data?.name_of_project} : {data?.description} : <a target="_blank" href={data?.link}>Link</a></li>
          </ul>
        );
      })}
    </div>
  );
};

const ProfessionalDetails = ({ professional }) => {
  return (
    <div className="mt-3">
      <h5>Professional</h5>
      {professional.profile_pic ? (
        <div className="row">
          <div className="col">Profile Picture</div>
          <div className="col">
            <img src={professional?.profile_pic} className="img-thumbnail" width={200} alt="None" />
          </div>
        </div>
      ) : null}
      <div className="row">
        <div className="col">Linked In</div>
        <div className="col">
          <a target="_blank" href={professional?.linked_in}>
            {professional?.linked_in}
          </a>
        </div>
      </div>
      {!professional.years_of_experience ? (
        <div className="row">
          <div className="col">Years of Experience</div>
          <div className="col">Fresher</div>
        </div>
      ) : (
        <>
          <div className="row">
            <div className="col">Years of Experience</div>
            <div className="col">{professional?.years_of_experience}</div>
          </div>
          <div className="row">
            <div className="col">Current CTC (Fixed)</div>
            <div className="col">{professional?.current_ctc_fixed}</div>
          </div>
          <div className="row">
            <div className="col">Current CTC (Variable)</div>
            <div className="col">{professional?.current_ctc_variable}</div>
          </div>
        </>
      )}
      <div className="row">
        <div className="col">Expected CTC</div>
        <div className="col">
          {professional?.expected_ctc}{" "}
          {professional?.negotiable ? " (Negotiable)" : "(non-negotiable)"}
        </div>
      </div>
      <div className="row">
        <div className="col">Notice Period</div>
        <div className="col">{professional?.notice_period}</div>
      </div>
      <div className="row">
        <div className="col">Skills</div>
        <div className="col">{professional?.skills?.join(", ")}</div>
      </div>
      <div className="row">
        <div className="col">Languges Known</div>
        <div className="col">{professional?.languages?.join(", ")}</div>
      </div>
    </div>
  );
};

const AdditionalDetails = ({ professional, education, experience, portfolio }) => {
  const createLink = (value, name) => {
    if (value) {
      return <a target="_blank" href={value}>{name}</a>
    }
    return null;
  }
  return (
    <div className="collapse multi-collapse" id="multiCollapseExample1">
      <div className="row">
        <div className="col">Links</div>
        <div className="col">
          {createLink(professional?.facebook, "Facebook")} {createLink(professional?.angellist, "Angellist")} {createLink(professional?.twitter, "Twitter")} 
        </div>
      </div>
      <div className="row">
        <div className="col">About</div>
        <div className="col"><ReadMoreReact text={professional?.about}
            min={25} ideal={30}  max={50}  readMoreText="Read more"/></div>
      </div>
      <div className="row">
        <div className="col">Roles interested in</div>
        <div className="col">{professional?.roles?.join(", ")}</div>
      </div>
      <div className="row">
        <div className="col">Preferred Locations</div>
        <div className="col">{professional?.locations?.join(", ")}</div>
      </div>
      <div className="row">
        <div className="col">Resume</div>
        <div className="col"><a target="_blank" href={professional?.resume}>Resume</a></div>
      </div>
      <EducationDetails education={education} />
      <WorkExperience experience={experience} />
      <Portfolio portfolio={portfolio} />
    </div>
  )
}

export default Index;
