import React, { useEffect } from "react";
import Navbar from "../../../Parts/Navbar";
import { useParams, useHistory } from "react-router";
import Card from "./Card";
import "./index.css";
import { useMutation } from "react-query";
import { toast, ToastContainer } from "react-toastify";
import {
  getAnswers,
  shortListCandidate,
  rejectCandidate,
  getCandidates,
} from "../../../API_CALLS/Admin";
import { useAtom } from "jotai";
import {
  answer as userAnswer,
  counterCandidate,
  candidates,
  showShortListed,
  candidateIDS_store
} from "../../../../Store/Users";

const Index = ({ candidate_ID, candidate_list_ids, candidate_list, filter_by_status }) => {
  const history = useHistory();
  const { position_id } = useParams();
  const [shortlistedAlready, setShortlistedAlready] = useAtom(showShortListed);
  const [, setCandidate] = useAtom(candidates);
  const [candidateCount, setCandidateCount] = useAtom(counterCandidate);
  const [candidateIds, setCandidate_Ids] = useAtom(candidateIDS_store);
  const { mutateAsync: candidateMutate, isError: candidateErr } = useMutation(getCandidates);
  const { mutateAsync, data } = useMutation(getAnswers);
  const shortlistAsync = useMutation(shortListCandidate);
  const rejectAsync = useMutation(rejectCandidate);
  const [, setAnswer] = useAtom(userAnswer);
  useEffect(() => {
    let isMounted = true;

    getAnswer(candidate_ID).then((data) => {
      if (isMounted) setAnswer(data);
    }).catch((e) => {
      console.log("err: ", e);
    })

    return () => {
      setCandidateCount(0);
      setShortlistedAlready(false);
      isMounted = false;
    };
  }, [candidate_ID]);

  const updateData = async (selectedPos) => {
    const response = await candidateMutate(selectedPos);
    setCandidate(response);
  };

  const getAnswer = async (candidate_ID) => {
    try {
      const answers = await mutateAsync(candidate_ID);
      return answers;
    } catch (e) {
      if (e.response.status === 404) {
        // history.push("/admin-home");
      }
    }
  };

  const shortlist = async (candidate_ID) => {
    try {
      const success = await shortlistAsync.mutateAsync(candidate_ID);
      if (success.status === "ok") {
        toast.success("Successfully Shortlisted The Candidate", {
          position: "bottom-center",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        const removeCurrentID = candidateIds.filter((id) => id != candidate_ID);
        if (!removeCurrentID.length) {
          history.push("/admin-home/" + position_id);
          return;
        } 
        console.log("Removed ID", removeCurrentID, candidateIds, candidate_ID)
        setCandidate_Ids(removeCurrentID);
        history.push("/admin-home/" + position_id + "?candidate_id=" + removeCurrentID[0]);
      }
    } catch (err) {}
  };

  const reject = async (candidate_ID) => {
    try {
      const success = await rejectAsync.mutateAsync(candidate_ID);
      if (success.status === "ok") {
        toast.error("Successfully Rejected The Candidate", {
          position: "bottom-center",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        const removeCurrentID = candidateIds.filter((id) => id != candidate_ID);
        if (!removeCurrentID.length) {
          history.push("/admin-home/" + position_id);
          return;
        } 
        console.log("Removed ID", removeCurrentID, candidateIds, candidate_ID)
        setCandidate_Ids(removeCurrentID);
        history.push("/admin-home/" + position_id + "?candidate_id=" + removeCurrentID[0]);
      }
    } catch (error) {}
  };
  const switchCandidate = async (candidate_ID, filter_by_status, candidate_list, next) => {
    let nextUser = null;
    if (!filter_by_status) {
      candidate_list.forEach((candidate, index) => {
        if (candidate.id === parseInt(candidate_ID)) {
          nextUser = index;
        }
      })
    }
    if (nextUser !== null) {
      const user = candidate_list[next ? nextUser + 1: nextUser - 1];
      if (user) {
        history.push("/admin-home/" + position_id + "?candidate_id=" + user.id);
      } else {
        toast.error("End of candidate", {autoClose: 1500});
      }
    }
  }

  return (
    <>
      <div className="custom-container">
        <h2 className="text-center mt-4 theme-primary-blue-text">Admin</h2>
        <i
          className="fas fa-arrow-circle-left h2 theme-primary-blue-text hover"
          onClick={() => {
            history.push("/admin-home/"+ position_id);
          }}
        >
          Back
        </i>
      </div>
      <Card />
      <div className="shortlist-reject pb-4">
          <>
            <button
              onClick={() => {
                return shortlist(candidate_ID);
              }}
              className="btn btn-success"
              disabled={data?.status === "SHORTLISTED" || data?.status === "REJECTED"}
            >
              Shortlist
            </button>
            <button
              onClick={() => {
                return reject(candidate_ID);
              }}
              className="btn btn-danger"
              disabled={data?.status === "SHORTLISTED" || data?.status === "REJECTED"}
            >
              Reject
            </button>
          </>
          <button
            onClick={() => {
              return switchCandidate(candidate_ID, filter_by_status, candidate_list, false);
            }}
            disabled={candidate_list?.[0]?.id === parseInt(candidate_ID)}
            className="btn btn-primary"
          >
            Prev Candidate
          </button>
          <button
            onClick={() => {
              console.log("Hey")
              return switchCandidate(candidate_ID, filter_by_status, candidate_list, true);
            }}
            disabled={candidate_list?.[candidate_list.length - 1]?.id === parseInt(candidate_ID)}
            className="btn btn-primary"
          >
            Next Candidate
          </button>
        </div>
    <ToastContainer position="bottom-center" />
    </>
  );
};

export default Index;
