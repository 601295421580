import React, { useState } from "react";
import ReactTooltip from "react-tooltip";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { SpinnerCircular } from 'spinners-react';
// API Call
import { useMutation } from "react-query";
import { candidatePersonalDetail } from "../../../../API_CALLS/Sign-up";
// State
import { useAtom } from "jotai";
import { isLoggedIn } from "../../../../../Store/Users";
import { sign_up_pointer } from "../../../../../Store/Signup";

const PersonalDetail = ({ data, userDetail }) => {
  const [linkedIn, setLinkedIn] = useState("");
  const [gender, setGender] = useState("");
  const [number, setNumber] = useState();
  const [wa_number, setWaNumber] = useState();
  const [isChecked, setIsChecked] = useState(true);
  const [err, setErr] = useState(false);
  // Global
  const [user] = useAtom(isLoggedIn);
  const [pointer, setPointer] = useAtom(sign_up_pointer);

  // API
  const submitPersonalDetail = useMutation(candidatePersonalDetail);

  const submitProfile = () => {
    let val = {
      number,
      wa_number: isChecked ? number : wa_number,
      gender: gender,
    };
    if (!(number && isValidPhoneNumber(number)) || gender === "") {
      return setErr(true);
    }
    if (!isChecked ? !(wa_number && isValidPhoneNumber(wa_number)) : false) {
      return setErr(true);
    }
    submitPersonalDetail
      .mutateAsync(val)
      .then((res) => {
        setPointer(pointer + 1);
      })
      .catch(() => {
        return;
      });
  };

  const createError = (err, isValid) => {
    if (!err || isValid) {
      return "form-control";
    } else {
      return "form-control border-danger";
    }
  };

  return (
    !submitPersonalDetail.isLoading ?
    <>
      <div className="container mt-4">
        <div className="row justify-content-center">
          <div className="col-md-8 mt-2">
            <div className="alert alert-warning">
              {data.message ? (
                <>
                  <strong>Congratulations</strong>, you are shortlisted for {data.message.role_name}{" "}
                  interview at {data.message.company_name}. To take the interview,
                  please fill your profile below. Your professional profile is
                  visible to recruiters at {data.message.company_name} when they are
                  evaluating your interview recordings, so please fill it
                  carefully to increase your chances of getting shortlisted for
                  next round.
                </>
              ) : (
                <>
                  Hundreds of companies are conducting pre-screening interviews
                  on our platform. Finish your profile now to apply on job
                  openings and get shortlisted.
                </>
              )}
            </div>
            <div className="form-group">
              <label>Name</label>
              <input
                type="text"
                className="form-control"
                disabled
                value={userDetail.first_name + " " + userDetail.last_name}
              />
            </div>
            <div
              onChange={(e) => setGender(e.target.value)}
              className={!err || gender !== "" ? "" : "text-danger"}
            >
              <label>Gender &nbsp;&nbsp;</label>
              <div className="form-check-inline mt-3">
                <input type="radio" name="gender" id="male" value="male" />
                <label htmlFor="male">&nbsp;Male</label>
              </div>
              <div className="form-check-inline mt-3">
                <input type="radio" name="gender" id="female" value="female" />
                <label htmlFor="female">&nbsp;Female</label>
              </div>
            </div>
            <div className="form-group mt-3">
              <label>
                Email id{" "}
                <span
                  className="fas fa-info-circle text-blue-400 btn"
                  data-tip
                  data-for="email"
                ></span>
              </label>
              <input
                type="text"
                className="form-control"
                value={userDetail?.email}
                disabled
              />
            </div>
            <div className="form-check-inline mt-2">
              <label>Phone Number</label>
              <PhoneInput
                placeholder="Enter phone number"
                className={createError(
                  err,
                  number && isValidPhoneNumber(number)
                )}
                value={number}
                onChange={setNumber}
              />
            </div>
            <div className="form-check-inline mt-2">
              <label>
                Whatsapp number{" "}
                <span
                  className="fas fa-info-circle text-blue-400 btn"
                  data-tip
                  data-for="whatsapp-num"
                ></span>
              </label>
              <PhoneInput
                placeholder="Enter phone number"
                className={
                  isChecked
                    ? "form-control"
                    : createError(
                        err,
                        wa_number && isValidPhoneNumber(wa_number)
                      )
                }
                disabled={isChecked}
                value={isChecked ? number : wa_number}
                onChange={setWaNumber}
              />
            </div>
            <div className="form-check-inline">
              <input
                type="checkbox"
                className="col"
                id="same-as-phone"
                checked={isChecked}
                onChange={(e) => setIsChecked(!isChecked)}
              />
              <label htmlFor="same-as-phone">Same as phone number</label>
            </div>
            <div className="text-danger">
              {submitPersonalDetail.isError
                ? "Phone Number already registered"
                : ""}
            </div>
            <div className="text-center mt-4">
              <button className="btn btn-success" onClick={submitProfile}>
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
      <ReactTooltip id="email" backgroundColor="#2a52a6" place="bottom">
        <div className="text-center">
          <p>This is the email to which all updates will be sent</p>
          <p>regarding your candidature from different companies</p>
        </div>
      </ReactTooltip>
      <ReactTooltip id="whatsapp-num" backgroundColor="#2a52a6" place="bottom">
        <div className="text-center">
          <p>To get timely reminders about your interviews on</p>
          <p>your whatsapp number.</p>
        </div>
      </ReactTooltip>
    </>: 
    <div className="text-center">
      <SpinnerCircular size={50} thickness={100} speed={100} color="#36ad47" secondaryColor="rgba(0, 0, 0, 0.44)" />
    </div> 
  );
};

export default PersonalDetail;
