import React, { useEffect, useState } from "react";
import Navbar from "../../../Parts/Navbar";
import Hero from "./Parts/Hero/Hero";
import Details from "./Parts/Details/";
import { useParams, useLocation } from "react-router-dom";
import "react-multi-email/style.css";
import ShowDetails from "../User-Result";
// Modals
import InviteModal from "./Parts/Hero/Modal-Invite";
import AddQuestion from "./Parts/Hero/Modal-AddQuestion";
import AddJob from "./Parts/Hero/Modal-Job";
import AddNoteModal from "./Parts/Hero/Modal-AddNote";
// Store
import { useAtom } from "jotai";
import { candidateIDS_store } from "../../../../Store/Users";
// API CALLS
import { useMutation } from "react-query";
import { getCandidates } from "../../../API_CALLS/Admin";


function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Index = () => {
  const { position_id } = useParams();
  const candidatesMutate = useMutation(getCandidates);

  let query = useQuery();
  const candidate_id = query.get("candidate_id");
  const filter_by_status = query.get("filter");
  const [candidateIds, setCandidateIds] = useAtom(candidateIDS_store); 

  useEffect(() => {
    if (position_id) {
      candidatesMutate.mutateAsync(position_id).then((data) => {
        const ids = [];
        data.candidates.forEach((candidate) => {
          if (candidate.status === "SHORTLISTED" || candidate.status === "REJECTED") {
            return;
          }
          ids.push(candidate.id);
        })
        setCandidateIds(ids);
      }).catch(() => {
        console.log("No Position Found");
      })
    }
  }, [position_id, candidate_id]);


  return (
    <>
    {
      !candidate_id ?
      <>
        <Hero candidate={candidatesMutate?.data} candidateIds={candidateIds} />
        {position_id && !candidatesMutate.isError ? <Details position_id={position_id} candidate={candidatesMutate?.data} /> : null}
        <InviteModal />
        <AddQuestion />
        <AddJob />
      </>
      : 
      <>
      <ShowDetails candidate_ID={candidate_id} candidate_list_ids={candidateIds} candidate_list={candidatesMutate?.data?.candidates} filter_by_status={filter_by_status} />
      </> 
    }
    {position_id && !candidatesMutate.isError ? <AddNoteModal position_id={position_id} />: null}
    </>
  );
};

export default Index;
