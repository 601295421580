import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "react-query";
import { getCompanies, addQuestions } from "../../../../../API_CALLS/Admin";
import { toast, ToastContainer } from "react-toastify";
import Toast from "../../../../../Utils/toast";

const AddQue = () => {
  const [company, setCompany] = useState("");
  const { data: companies } = useQuery("get-companies", getCompanies);
  const { mutateAsync } = useMutation(addQuestions);
  const [formValues, setFormValues] = useState([
    { question_text: "", time_alloted: "", time_alloted_sec: "" },
  ]);
  const [batch, setBatch] = useState("");
  const selectCompany = (e) => {
    setCompany(e.target.value);
  };

  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  let addFormFields = () => {
    setFormValues([
      ...formValues,
      { question_text: "", time_alloted_min: "", time_alloted_sec: "" },
    ]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  let handleSubmit = async (event) => {
    event.preventDefault();
    const values = formValues.map((data) => {
      return {
        question_text: data.question_text,
        time_alloted:
          parseInt(data.time_alloted_min * 60) +
          parseInt(data.time_alloted_sec),
      };
    });
    if (batch === "") {
      return Toast(false, "Enter a batch name to continue");
    }
    const data = {
      company: parseInt(company),
      batch_name: batch,
      questions: values,
    };
    try {
      const res = await mutateAsync(data);
      if (res.status === "ok") {
        setFormValues([
          { question_text: "", time_alloted_min: "", time_alloted_sec: "" },
        ]);
        setBatch("");
        Toast(true, "Questions Successfully added");
      }
    } catch (e) {
      console.log(e.response);
    }
  };

  return (
    <div>
      <div
        className="modal fade"
        id="add-question"
        tabIndex={1}
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalToggleLabel1">
                Add Question
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="container">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Companies:</label>
                  <select
                    className="form-select mb-3"
                    aria-label=".form-select-lg example"
                    onChange={selectCompany}
                  >
                    <option defaultValue="" selected>
                      Select Company
                    </option>
                    {companies?.map((company, key) => {
                      return (
                        <option key={key} value={company.id}>
                          {company.company_name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {company !== "" ? (
                  <div>
                    {/* Form */}
                    <form onSubmit={handleSubmit}>
                      <div>
                        <label htmlFor="batch_name">Batch Name</label>
                        <input
                          className="form-control form-control"
                          type="text"
                          placeholder="For Ex. Introduction?"
                          aria-label=".form-control-lg example"
                          name="batch_name"
                          value={batch}
                          onChange={(e) => {
                            setBatch(e.target.value);
                          }}
                          required
                        />
                      </div>
                      <div className="row mb-2">
                        <div className="col-6">Question Text: </div>
                        <div className="col-1">Time: </div>
                      </div>
                      {formValues.map((element, index) => (
                        <div className="row mb-2 form-inline" key={index}>
                          <div className="col-6">
                            <input
                              className="form-control form-control"
                              type="text"
                              placeholder="For Ex. What is your hobby?"
                              aria-label=".form-control-lg example"
                              name="question_text"
                              value={element.question_text || ""}
                              onChange={(e) => handleChange(index, e)}
                              required
                            />
                          </div>
                          <div className="col-2 time-input">
                            <input
                              className="form-control form-control "
                              type="number"
                              name="time_alloted_min"
                              placeholder="min"
                              aria-label=".form-control-lg example"
                              min="0"
                              value={element.time_alloted_min || ""}
                              onChange={(e) => handleChange(index, e)}
                              required
                            />
                            <span>:</span>
                            <input
                              className="form-control form-control "
                              type="number"
                              name="time_alloted_sec"
                              placeholder="sec"
                              aria-label=".form-control-lg example"
                              min="0"
                              max="60"
                              value={element.time_alloted_sec || ""}
                              onChange={(e) => handleChange(index, e)}
                              required
                            />
                          </div>

                          {index ? (
                            <div className="col-3 text-danger align-self-center hover">
                              <i
                                className="fas fa-trash-alt h4"
                                onClick={() => removeFormFields(index)}
                              ></i>
                            </div>
                          ) : null}
                        </div>
                      ))}
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => addFormFields()}
                        >
                          Add Field
                        </button>
                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddQue;
