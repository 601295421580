import * as Yup from 'yup';
const url_reg = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/

const validationPortfolio = Yup.object().shape({
  portfolio: Yup.array()
      .of(
        Yup.object().shape({
          name_of_project: Yup.string()
            .required("Name of project is required"),
          description: Yup.string(),
          link: Yup.string()
            .required("LINK/URL is required")
            .matches(url_reg, "Enter a valid URL")
        })
      )
});

export default validationPortfolio;