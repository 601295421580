import React, { useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SpinnerCircular } from "spinners-react";
// Schema
import validationEducation from "./validation/validation_work";
// API Calls
import { useMutation } from "react-query";
import { candidateWorkExperience } from "../../../../API_CALLS/Sign-up";
// Store
import { useAtom } from "jotai";
import { sign_up_pointer, skip_experience } from "../../../../../Store/Signup";
// Utils
import formatDate from "../../../../Utils/formatDate";

const WorkExperienceDetails = () => {
  const formOptions = { resolver: yupResolver(validationEducation) };
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm(formOptions);
  const { fields, append, remove } = useFieldArray({
    control,
    name: "work_experience",
  });
  useEffect(() => {
    append({designation: "", organization: "", to_date: "", from_date: "", current: false})
  }, []);

  const currentWatch = watch(["work_experience"].current)
  const candidateWorkExperience_API = useMutation(candidateWorkExperience);
  const [pointer, setPointer] = useAtom(sign_up_pointer);
  const [, setExperience] = useAtom(skip_experience);

  const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
  const getYears = () => {
    let arr = [];
    for (let index = new Date().getFullYear(); index >= 1950; index--) {
      arr.push(index);
    }
    return arr;
  }

  const createDateFormat = (year, month) => {
    return month.toString().length === 1 ? year  + "-0" + month + "-01": year  + "-" + month + "-01"; 
  }

  return (
    !candidateWorkExperience_API.isLoading ? 
    <>
      <div className="container mt-4">
        <div className="row justify-content-center">
          <form
            className="col-md-8 mt-5"
            onSubmit={handleSubmit((data) => {
              const fixDate = data.work_experience.map((education) => {
                education.from_date = createDateFormat(education.from_year, education.from_month)
                if (education.current) {
                  education.to_date = formatDate(new Date())
                } else {
                  education.to_date = createDateFormat(education.to_year, education.to_month)
                }
                return education;
              })
              candidateWorkExperience_API.mutateAsync({ work_experience: fixDate }).then(() => {
                setPointer(pointer + 1);
              }).catch((e) => {
                console.log("Error: ", e);
              })
            })}
          >
            {fields.map((item, index) => {
              return (
                <div key={item.id}>
                  <div className="row mt-2">
                    <label
                      htmlFor="inputEmail3"
                      className="col-sm-2 col-form-label"
                    >
                      Designation
                    </label>
                    <div className="col-sm-10">
                      <Controller 
                        name={`work_experience.${index}.designation`}
                        control={control}
                        defaultValue={item.designation}
                        render={({field}) =>  <input type="text" className={"form-control " + (errors.work_experience?.[index]?.designation && "border-danger")} {...field} />}
                      />
                      <div className="form-check-inline">
                        <Controller 
                          name={`work_experience.${index}.current`}
                          control={control}
                          defaultValue={item.current}
                          render={({field}) =>  <input type="checkbox" {...field} />}
                        />
                        <label>
                          &nbsp;I currently work here
                        </label>
                        <div className='text-danger'>
                          {errors.work_experience?.[index]?.designation?.message}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <label
                      htmlFor="inputEmail3"
                      className="col-sm-2 col-form-label"
                    >
                      Organization
                    </label>
                    <div className="col-sm-10">
                      <Controller 
                        name={`work_experience.${index}.organization`}
                        control={control}
                        defaultValue={item.organization}
                        render={({field}) =>  <input type="text" className={"form-control " + (errors.work_experience?.[index]?.organization && "border-danger")} {...field} />}
                      />
                      <span className='text-danger'>
                        {errors.work_experience?.[index]?.organization?.message}
                      </span>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <label
                      htmlFor="inputEmail3"
                      className="col-sm-2 col-form-label"
                    >
                      Time Period
                    </label>
                    <div className="col-sm-10">
                      From
                      <div className="row">
                        <div className="col-md-6">
                          <Controller 
                            name={`work_experience.${index}.from_month`}
                            control={control}
                            defaultValue={item.from_month}
                            render={({field}) =>  
                            <select className={"form-select " + (errors.work_experience?.[index]?.from_month && "border-danger")} {...field}>
                              <option value="">Select Month</option>
                              {month.map((month, index) => <option key={index} value={index + 1}>{month}</option>)}
                            </select>}
                          />
                          <span className='text-danger'>
                            {errors.work_experience?.[index]?.from_month?.message}
                          </span>
                        </div>
                        <div className="col-md-6">
                          <Controller 
                            name={`work_experience.${index}.from_year`}
                            control={control}
                            defaultValue={item.from_year}
                            render={({field}) =>  
                            <select className={"form-select " + (errors.work_experience?.[index]?.from_year && "border-danger")} {...field}>
                              <option value="">Select Year</option>
                              {getYears().map((year, index) => <option key={index} value={year}>{year}</option>)}
                            </select>}
                          />
                          <span className='text-danger'>
                            {errors.work_experience?.[index]?.from_year?.message}
                          </span>
                        </div>
                      </div>
                      {!currentWatch.work_experience[index].current ?
                      <>
                      To
                        <div className="row">
                          <div className="col-md-6">
                            <Controller 
                              name={`work_experience.${index}.to_month`}
                              control={control}
                              defaultValue={item.to_month}
                              render={({field}) =>  
                              <select className={"form-select " + (errors.work_experience?.[index]?.to_month && "border-danger")} {...field}>
                                <option value="">Select Month</option>
                                {month.map((month, index) => <option key={index} value={index + 1}>{month}</option>)}
                              </select>}
                            />
                            <span className='text-danger'>
                              {errors.work_experience?.[index]?.to_month?.message}
                            </span>
                          </div>
                          <div className="col-md-6">
                            <Controller 
                              name={`work_experience.${index}.to_year`}
                              control={control}
                              defaultValue={item.to_year}
                              render={({field}) =>  
                              <select className={"form-select " + (errors.work_experience?.[index]?.to_year && "border-danger")} {...field}>
                                <option value="">Select Year</option>
                                {getYears().map((year, index) => <option key={index} value={year}>{year}</option>)}
                              </select>}
                            />
                            <span className='text-danger'>
                              {errors.work_experience?.[index]?.to_year?.message}
                            </span>
                          </div>
                        </div>
                      </>
                      :null
                      } 
                    </div>
                  </div>
                  <div className="mt-4 text-end">
                    {fields.length === index + 1 ? <span className="btn btn-dark" onClick={() => append({designation: "", organization: "", to_date: "", from_date: "", current: false})}>Add another work experience</span>: null }
                    &nbsp;&nbsp;&nbsp;
                    {fields.length !== 1 ? <button className="btn btn-danger" onClick={() => remove(index)}>Delete</button>: null}
                  </div>
                </div>
              );
            })}

            <div className="text-center mt-4 pb-4">
              <span className="btn btn-dark" onClick={() => {
                candidateWorkExperience_API.mutateAsync({ skip: true }).then(() => {
                  setPointer(pointer + 1);
                }).catch((e) => {
                  console.log("Error: ", e);
                })
                }}>Skip I am a fresher</span>
              &nbsp;&nbsp;&nbsp;
              <button className="btn btn-success" type="submit">Next</button>
            </div>
          </form>
        </div>
      </div>
    </>: 
    <div className="text-center">
      <SpinnerCircular size={50} thickness={100} speed={100} color="#36ad47" secondaryColor="rgba(0, 0, 0, 0.44)" />
    </div> 
  );
};

export default WorkExperienceDetails;
