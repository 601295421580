import React from "react";
import Player from "../Player";

const Index = ({
  id,
  question,
  start,
  stop,
  deleteAudio,
  recordingStatus,
  blob,
  duration,
}) => {
  return (
    <div className="col-md-8 mt-4">
      <div className="row mt-4">
        <span
          className="col-1"
          style={{ fontSize: "1.5rem", display: "inline-block" }}
        >
          {id + 1}
        </span>
        <p className="col-11">{question}</p>
        <Player
          start={start}
          stop={stop}
          deleteAudio={deleteAudio}
          recordingStatus={recordingStatus}
          id={id}
          blob={blob}
          duration={duration}
        />
      </div>
    </div>
  );
};

export default Index;
