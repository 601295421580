import React, { useState, useEffect } from "react";
import "./Player.css";

// Store
import { useAtom } from "jotai";
import {
  currentRecording,
  chunkStore,
  intervalStore,
  skipStatus,
  currentQuesstion,
  sampleURL,
  chunkData
} from "../../../Store/Users";
// API CALLS
import { useMutation } from "react-query";
import { submitAnswer } from "../../API_CALLS/Users";
import { useHistory } from "react-router-dom";
// Add On
import { ToastContainer } from "react-toastify";
import Toast from "../../Utils/toast";

const Player = ({
  question_timer,
  candidate_job_id,
  question_time,
  question_sno,
  sample = false,
  company_name
}) => {
  const history = useHistory();
  const [sampleAud, setSampleAud] = useAtom(sampleURL);
  const [, setRecording] = useAtom(currentRecording);
  const [recStatus, setRecStatus] = useState("idle");
  const [progress, setProgress] = useState(0);
  let [chunks, setChunk] = useAtom(chunkStore);
  const [, setChunkData] = useAtom(chunkData);
  const minutes = `${Math.floor(progress / 60)}`;
  const percentage = (progress * 100) / question_timer;

  // Current Question
  const [currentQue, setCurrentQue] = useAtom(currentQuesstion);

  // Skip Status From Footer
  const [status] = useAtom(skipStatus);

  // Submit Answer
  const [interval] = useAtom(intervalStore);
  const { mutateAsync, error } = useMutation(submitAnswer, { retry: false });

  // Get Next Question
  useEffect(() => {
    setRecStatus("idle");
    setProgress(0);

    return () => {
      setRecStatus("idle");
      setProgress(0);
      setChunk([]);
      if (window?.mediaRecorder?.state !== undefined) {
        window.mediaStream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, [question_sno]);

  const reverseTimer = (timer) => {
    if (timer <= 0) {
      return "00:00";
    }
    const minutes = `${Math.floor(timer / 60)}`;
    return `0${minutes % 60}`.slice(-2) + "." + `0${timer % 60}`.slice(-2);
  };

  const toggleRecording = () => {
    if (recStatus === "idle") {
      console.log(recStatus);
      const audioMediaConstraints = {
        audio: true,
        video: false,
      };
      navigator.mediaDevices
        .getUserMedia(audioMediaConstraints)
        .then((mediaStream) => {
          setRecStatus("started");
          window.mediaStream = mediaStream;
          const mediaRecorder = new MediaRecorder(mediaStream, {
            audioBitsPerSecond: 12800,
            MimeType: 'audio/webm;codecs="opus"',
          });
          mediaRecorder.start(1000);
          window.mediaStream = mediaStream;
          window.mediaRecorder = mediaRecorder;

          mediaRecorder.ondataavailable = (e) => {
            chunks.push(e.data);
            setChunk(chunks);
            setProgress(chunks.length);
          };

          if (sample) {
            mediaRecorder.onpause = async (e) => {
              const blob = new Blob(chunks, {
                type: "audio/wav",
              });
              const sampleURL = URL.createObjectURL(blob);
              setSampleAud(sampleURL);
            };
          }

          mediaRecorder.onstop = async (e) => {
            const blob = new Blob(chunks, {
              type: "audio/wav",
            });
            setChunkData(blob);
            chunks = [];
            setChunk([]);
          };
        })
        .catch((e) => {
          if (e.name === "NotAllowedError") {
            Toast(false, "Please Enable Microphone permission and continue");
          }
        });
    } else if (recStatus === "resume") {
      setRecStatus("started");
      window.mediaRecorder.resume();
    } else {
      setRecStatus("resume");
      window.mediaRecorder.pause();
    }
  };

  const btnStatus = (status) => {
    switch (status) {
      case "idle":
        return "fa-microphone";
      case "started":
        return "fa-pause";
      case "resume":
        return "fa-microphone";
    }
  };

  return (
    <div>
      <div className="alert alert-warning container mt-4" role="alert">
        <strong>Note:</strong> After careful consideration, the HR team at {company_name}{" "}
        has allowed maximum{" "}
        {`0${Math.floor(question_time / 60) % 60}`.slice(-2)}:
        {`0${question_time % 60}`.slice(-2)} minutes for this question. To
        increase your chances of getting shortlisted, please record a
        comprehensive answer that actually covers most of this duration (say
        over {`0${Math.floor((question_time - 60) / 60) % 60}`.slice(-2)}:
        {`0${(question_time - 60) % 60}`.slice(-2)} minutes). Our data suggests
        that recruiters tend to reject candidates with short answers i.e.
        candidates who cut short their answers & don’t utilize max time allowed
        fully (to answer a particular question).
      </div>
      <div className="question-remaining-time text-center mt-4">
        <p>Question remaining time</p>
        <div className="card">{reverseTimer(question_timer)}</div>
      </div>
      <div className="up-player-container">
        <div className="card text-dark">
          <div className="row">
            <div className="col-2">
              <div className="mic-circle" onClick={toggleRecording}>
                <i className={`fas ${btnStatus(recStatus)}`}></i>
              </div>
            </div>
            <div className="col-2">
              <div>
                {`0${minutes % 60}`.slice(-2)}.{`0${progress % 60}`.slice(-2)}
              </div>
              <span>
                {`0${Math.floor(question_time / 60) % 60}`.slice(-2)}:
                {`0${question_time % 60}`.slice(-2)} Min
              </span>
            </div>
            <div className="col-8">
              <div className="bar">
                <div className="prog" style={{ width: percentage + "%" }}></div>
                <div className="progress"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Player;
