import React from "react";
import "./Hero.css";

const Index = () => {
  return (
    <div className="container">
      <div className="row hero">
        <div>
          <h1>
            Let us Help <br /> You to Build Your Dream
          </h1>
        </div>
        <div className="col-md-5 mt-4">
          <p>
            Get hired by taking hassle-free audio interviews from top companies
            around the world.
          </p>
        </div>
      </div>
      <div className="row justify-content-center google-btn-container">
        <div>
          <a
            href={`${process.env.REACT_APP_ROOT_URL}/accounts/google/login/`}
            rel="noreferrer"
            className="btn google-btn"
          >
            <img
              src="https://hiring.maakeetoo.com/static/images/google_login_logo.ico"
              width={35}
              alt=""
            />{" "}
            Sign in with Google
          </a>
        </div>
      </div>
    </div>
  );
};

export default Index;
