import React from 'react'
import './Breadcrumb.css'

const DetailsHeader = ({ active, data }) => {
  const activeStep = (active, active_dom, isCompleted) => {
    if (active === active_dom) {
      return "active"
    }
    if (isCompleted) {
      return "completed"
    }
  }

  return (
    <div className="container">
      <div className="bread-comp ">
        <ul className="row breadcrumb justify-content-between text-center">
          <li className={activeStep(active, 0, data.personal) + " col"}>
            <span>1. Personal Details</span>
          </li>
          <li className={activeStep(active, 1, data.professional) + " col"}>
            <span>2. Professional Details</span>
          </li>
          <li className={activeStep(active, 2, data.education) + " col"}>
            <span>3. Education</span>
          </li>
          <li className={activeStep(active, 3, data.work_experience) + " col"}>
            <span>4. Work Experience</span>
          </li>
          <li className={activeStep(active, 4, data.portfolio) + " col"}>
            <span>5. Portfolio</span>
          </li>
        <hr className='bg-line' />
        </ul>
      </div>
    </div>
  )
}

export default DetailsHeader
