import React from "react";
import { useHistory } from "react-router-dom";
import "./Nav.css";
import { useAtom } from "jotai";
import { isLoggedIn } from "../../../Store/Users";
import axios from "axios";

const Navbar = ({ loggedIn }) => {
  const [loggedInState, setLoggedIn] = useAtom(isLoggedIn);
  const logout = async () => {
    const response = await axios.get(
      process.env.REACT_APP_ROOT_URL + "/logout"
    );
    window.location.reload(false);
  };

  return (
    <nav className="navbar text-white bg-dark">
      <div className="container-fluid">
        <div className="nav-brand">
          <img
            src="https://hiring.maakeetoo.com/static/images/hiring_tool_logo.png"
            alt="Logo"
            width={30}
          />
          <span>Hiring Tool</span>
        </div>
        {loggedIn ? (
          <div className="d-flex icons">
            {/* <a className="fas fa-user"></a> */}
            <a
              // target="_blank"
              rel="noreferrer"
              // href={process.env.REACT_APP_ROOT_URL + "/logout"}
              onClick={logout}
              className="fas fa-sign-out-alt"
            ></a>
          </div>
        ) : null}
      </div>
    </nav>
  );
};

export default Navbar;
