import React, { useEffect, useState } from "react";
import Navbar from "../../../Parts/Navbar";
import Header from "../../../Parts/Questions/Header";
import Player from "../../../Parts/Questions/Player";
import { Footer } from "../../../Parts/Questions/FooterBtn";
import { useHistory, useLocation } from "react-router";
import { SpinnerCircular } from "spinners-react";
// API CALLS
import { useMutation } from "react-query";
import { getNextQuestion, submitAnswer } from "../../../API_CALLS/Users";
// Store
import { useAtom } from "jotai";
import {
  totalTimer,
  selectedJob,
  questions,
  intervalStore,
  queTimerStore,
  currentQuesstion,
  onlyTotalTime,
} from "../../../../Store/Users";
// Add on
import { ToastContainer, toast } from "react-toastify";

function RouterQuery() {
  return new URLSearchParams(useLocation().search);
}

const Index = () => {
  const history = useHistory();
  const query = RouterQuery();
  const candidate_job_id = parseInt(query.get("id"));
  const { mutateAsync } = useMutation(getNextQuestion);
  const submitAnswer_API = useMutation(submitAnswer);
  // Getting All the question
  const [val] = useAtom(selectedJob);

  useEffect(() => {
    if(!total_questions) {
      history.push("/");
    }
  }, [])
  const total_time = val?.total_time
  const total_questions = val?.total_questions
  // Current Pointer
  const [currentQue] = useAtom(currentQuesstion);

  // Getting Single Question
  const [question_list] = useAtom(questions);
  // Vars
  const question_sno = question_list[currentQue]?.question_sno;
  const queTimeAllowed = question_list[currentQue]?.time_alloted;
  
  const [que_data, setQuestion] = useState({});
  const quesion_text = que_data?.quesion_text;
  const time_alloted = que_data?.time_allowed;
  const required = que_data?.required;
  const companyName = que_data?.company_name;
  
  // Loading Total Timer
  const [totalLoadingTimer, setTotalTimer] = useAtom(totalTimer);
  // Loading Question Timer
  const [interval, setResetInterval] = useAtom(intervalStore);
  const [queTimer, setQueTimer] = useAtom(queTimerStore);

  const [total_timer] = useAtom(onlyTotalTime);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) setQueTimer(queTimeAllowed);
    getQuestion().then((data) => {
      // Setting Single Question To VD (Virtual DOM)
      var date = new Date(data?.start_time * 1000);
      const currentTime = new Date();
      var seconds = (currentTime.getTime() - date.getTime()) / 1000;
      if (isMounted) setTotalTimer(total_timer - Math.round(seconds));
      if (isMounted) setQuestion(data);
      if (isMounted)
        setResetInterval(
          setInterval(() => {
            setQueTimer((queTimer) => queTimer - 1);
          }, 1000)
        );
    });

    return () => {
      isMounted = false;
      clearInterval(interval);
      if (currentQue > question_list.length) {
        history.push("/success");
      }
    };
  }, [currentQue]);

  const getQuestion = async () => {
    try {
      const response = await mutateAsync({ question_sno, candidate_job_id });
      return response;
    } catch (err) {
      console.log(err.response);
    }
  };

  return (
    <>
    {!val ? null: 
    <div className="start-interview-container">
      <Header
        total_timer={totalLoadingTimer}
        quesion_text={quesion_text}
        question_sno={question_sno}
        total_questions={total_questions}
        question_timer={queTimer}
        max_int_time={total_time}
      />
      {!submitAnswer_API.isLoading ?
        <>
        <Player
        question_timer={queTimer}
        candidate_job_id={candidate_job_id}
        question_time={queTimeAllowed}
        question_sno={question_sno}
        company_name={companyName}
      />
      <Footer
        candidate_job_id={candidate_job_id}
        question_sno={question_sno}
        total_timer={totalLoadingTimer}
        required={required}
        question_timer={time_alloted}
        submitAnswer_API={submitAnswer_API}
      />
      </>:
      <div className="text-center">
        <div className="text-center mt-4">  
         <p className="text-danger">Answer is getting submitted! please wait...</p>
          <SpinnerCircular size={50} thickness={100} speed={100} color="#2a52a6" secondaryColor="rgba(0, 0, 0, 0.44)" />
        </div> 
      </div>}
      <ToastContainer position="bottom-center" />
    </div>
    }
    </>
  );
};

export default Index;
