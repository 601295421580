import React from "react";
import ReactHowler from "react-howler";
import raf from "raf"; // requestAnimationFrame polyfill
import "./adminplayer.css";

class Index extends React.Component {
  speed = [1, 1.25, 1.5, 1.75, 2];
  constructor(props) {
    super(props);

    this.state = {
      playing: false,
      loaded: false,
      mute: false,
      volume: 1.0,
      seek: 0.0,
      rate: 1,
      isSeeking: false,
      volVisiblity: true,
      speedVisibility: true,
    };
    this.handleToggle = this.handleToggle.bind(this);
    this.handleOnLoad = this.handleOnLoad.bind(this);
    this.handleOnEnd = this.handleOnEnd.bind(this);
    this.handleOnPlay = this.handleOnPlay.bind(this);
    this.handleStop = this.handleStop.bind(this);
    this.renderSeekPos = this.renderSeekPos.bind(this);
    this.handleMuteToggle = this.handleMuteToggle.bind(this);
    this.handleMouseDownSeek = this.handleMouseDownSeek.bind(this);
    this.handleMouseUpSeek = this.handleMouseUpSeek.bind(this);
    this.handleSeekingChange = this.handleSeekingChange.bind(this);
    this.handleRate = this.handleRate.bind(this);
  }

  componentWillUnmount() {
    this.clearRAF();
  }

  handleToggle() {
    this.setState({
      playing: !this.state.playing,
    });
  }

  handleOnLoad() {
    this.setState({
      loaded: true,
      duration: this.player?.duration(),
    });
  }

  handleOnPlay() {
    this.setState({
      playing: true,
    });
    this.renderSeekPos();
  }

  handleOnEnd() {
    this.setState({
      playing: false,
    });
    this.clearRAF();
  }

  handleStop() {
    this.player.stop();
    this.setState({
      playing: false, // Need to update our local state so we don't immediately invoke autoplay
    });
    this.renderSeekPos();
  }

  handleMuteToggle() {
    this.setState({
      mute: !this.state.mute,
    });
  }

  handleMouseDownSeek() {
    this.setState({
      isSeeking: true,
    });
  }

  handleMouseUpSeek(e) {
    this.setState({
      isSeeking: false,
    });

    this.player.seek(e.target.value);
  }

  handleSeekingChange(e) {
    this.setState({
      seek: parseFloat(e.target.value),
    });
  }

  renderSeekPos() {
    if (!this.state.isSeeking) {
      this.setState({
        seek: this.player.seek(),
      });
    }
    if (this.state.playing) {
      this._raf = raf(this.renderSeekPos);
    }
  }

  handleRate(val) {
    const rate = parseFloat(val);
    this.player.rate(rate);
    this.setState({ rate });
  }

  clearRAF() {
    raf.cancel(this._raf);
  }

  render() {
    return (
      <div className="up-player-container">
        <ReactHowler
          src={this.props.audioURL}
          playing={this.state.playing}
          onLoad={this.handleOnLoad}
          onPlay={this.handleOnPlay}
          onEnd={this.handleOnEnd}
          mute={this.state.mute}
          volume={this.state.volume}
          ref={(ref) => (this.player = ref)}
        />

        <div className="card text-dark">
          <div className="row">
            <div className="col-2">
              <div className="mic-circle" onClick={this.handleToggle}>
                <i
                  className={`fas ${
                    this.state.playing ? "fa-pause" : "fa-play"
                  }`}
                ></i>
              </div>
            </div>
            <div className="col-2">
              <div>
                {`0${Math.floor(this.state.seek / 60) % 60}`.slice(-2)}.
                {`0${Math.round(this.state.seek) % 60}`.slice(-2)}
              </div>
              <span>
                {this.state.duration
                  ? (this.state.duration / 60).toFixed(1)
                  : "NaN"}{" "}
                min
              </span>
            </div>
            <div className="col-5">
              <span className="slider-container">
                <input
                  type="range"
                  min="0"
                  max={this.state.duration ? this.state.duration.toFixed(2) : 0}
                  step=".01"
                  value={this.state.seek}
                  onChange={this.handleSeekingChange}
                  onMouseDown={this.handleMouseDownSeek}
                  onMouseUp={this.handleMouseUpSeek}
                />
              </span>
            </div>
            <div className="col-1 vol-slider">
              {this.state.volVisiblity ? (
                <i
                  className="fas fa-volume-up hover h5 mt-2"
                  onMouseEnter={() => {
                    this.setState({ volVisiblity: false });
                  }}
                ></i>
              ) : (
                <span
                  className="slider-container"
                  onMouseLeave={() => {
                    this.setState({ volVisiblity: true });
                  }}
                >
                  <input
                    type="range"
                    className="form-range"
                    min="0"
                    max="1"
                    step=".05"
                    value={this.state.volume}
                    onChange={(e) =>
                      this.setState({ volume: parseFloat(e.target.value) })
                    }
                  />
                </span>
              )}
            </div>
            <div
              className="col-1 vol-slider hover"
              onMouseEnter={() => {
                this.setState({ speedVisibility: false });
              }}
              onMouseLeave={() => {
                this.setState({ speedVisibility: true });
              }}
            >
              <span>{this.state.rate}x</span>
              {!this.state.speedVisibility ? (
                <ul>
                  {this.speed.map((speed, key) => {
                    return (
                      <li
                        key={key}
                        onClick={() => {
                          this.handleRate(speed);
                        }}
                        className={
                          speed === this.state.rate
                            ? "text-danger"
                            : "text-white"
                        }
                      >
                        {speed}x
                      </li>
                    );
                  })}
                </ul>
              ) : null}

              {/* <input
                    type="range"
                    className="form-range"
                    min="0.1"
                    max="3"
                    step="0.5"
                    value={this.state.rate}
                    onChange={this.handleRate}
                  /> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Index;
