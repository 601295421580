import axios from "axios";
import { v4 as uuidv4 } from "uuid";

axios.defaults.withCredentials = true;
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

const candidatePersonalDetail = async (inputData) => {
  const { data } = await axios.post(process.env.REACT_APP_ROOT_URL + "/hiring/user/personal", inputData,
    { withCredentials: true }
  );
  return data;
};

const candidateProfessionalDetail = async (inputData) => {
  const { data } = await axios.post(process.env.REACT_APP_ROOT_URL + "/hiring/user/professional", inputData,
    { 
      withCredentials: true,
      "content-type": "multipart/form-data",
    }
  );
  return data;
};

const candidateWorkExperience = async (inputData) => {
  const { data } = await axios.post(process.env.REACT_APP_ROOT_URL + "/hiring/user/workexperience", inputData,
    { withCredentials: true }
  );
  return data;
};

const candidateEducationDetail = async (inputData) => {
  const { data } = await axios.post(process.env.REACT_APP_ROOT_URL + "/hiring/user/education", inputData,
    { 
      withCredentials: true,
      "content-type": "application/json",
    }
  );
  return data;
};

const candidatePortfolio = async (inputData) => {
  const { data } = await axios.post(process.env.REACT_APP_ROOT_URL + "/hiring/user/portfolio", inputData,
    { withCredentials: true }
  );
  return data;
};

const dataFilled = async () => {
  const { data } = await axios.get(process.env.REACT_APP_ROOT_URL + "/hiring/user/datafilled", { withCredentials: true });
  return data;
};

const skillList = async ({search}) => {
  const { data } = await axios.get(process.env.REACT_APP_ROOT_URL + `/hiring/options/skills?search=${search}`, { withCredentials: true });
  return data;
};
const rolesList = async ({search}) => {
  const { data } = await axios.get(process.env.REACT_APP_ROOT_URL + `/hiring/options/roles?search=${search}`, { withCredentials: true });
  return data;
};
const locationList = async ({search}) => {
  const { data } = await axios.get(process.env.REACT_APP_ROOT_URL + `/hiring/options/locations?search=${search}`, { withCredentials: true });
  return data;
};
const languageList = async ({search}) => {
  const { data } = await axios.get(process.env.REACT_APP_ROOT_URL + `/hiring/options/languages?search=${search}`, { withCredentials: true });
  return data;
};

export { 
  candidatePersonalDetail, 
  candidateEducationDetail, 
  candidatePortfolio, 
  candidateProfessionalDetail, 
  candidateWorkExperience, 
  dataFilled,
  skillList,
  rolesList,
  locationList,
  languageList
 }