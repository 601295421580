import React, { useState } from "react";
// API CALl
import { useMutation } from "react-query";
import { addCommonNote } from "../../../../../API_CALLS/Admin";
import { toast } from "react-toastify";
// Store
import { useAtom } from "jotai";
import { custom_notes } from "../../../../../../Store/Users";


const AddNoteModal = ({ position_id }) => {
  const [note, setNote] = useState("");
  const [customNote, setCustomNote] = useAtom(custom_notes);
  const addCommonNote_API = useMutation(addCommonNote);
  const addNote = () => {
    if (note === "") {
      toast.error("Note cannot be empty", {autoClose: 1500});
      return
    }
    addCommonNote_API.mutateAsync({ note, id: position_id }).then(() => {

      setCustomNote([...customNote, {note}]);
      toast.success("Added success", {autoClose: 1500});
    }).catch(() => {
      toast.error("Failed Adding Note", {autoClose: 1500});
    })
  }
  return (
    <div>
      <div
        className="modal fade"
        id="add-common-note-modal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Common Note
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
            <div className="form-group">
              <label for="exampleInputEmail1">Common Note</label>
              <input type="text" onChange={(e) => setNote(e.target.value)} value={note} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter your note" />
            </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={addNote}
                >
                  Add Note
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNoteModal;
