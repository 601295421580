import { atom } from "jotai";


const sign_up_pointer = atom(0);
const is_fresher = atom(false);
const skip_experience = atom(false);

export {
  sign_up_pointer,
  is_fresher,
  skip_experience
}