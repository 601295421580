import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { createBrowserHistory } from "history";
import { PrivateRouteReact, PrivateRouteReactContext, hasAnyAuthority } from "teemsly-auth"
import { useQuery, useMutation } from "react-query";
// Store
import { useAtom } from "jotai";
import { isLoggedIn } from "./Store/Users";

// API Calls
import { getUserDetails } from "./components/API_CALLS/Users";
import { dataFilled } from "./components/API_CALLS/Sign-up"
// Protected Routes
import ProtectedRoute from "./components/Protected";
import AdminRoute from "./components/AdminProtected";

// User
import {
  Home,
  UploadCV,
  AudioRec,
  Rules,
  Questions,
  Success,
  JobOpening,
  SampleInterview,
} from "./components/Pages/User/index";
import Navbar from "./components/Parts/Navbar";
import TestComp from "./components/Pages/User/Signup";

// Admin
import { AdminHome, UserResult } from "./components/Pages/Admin";

const App = () => {
  const [loggedIn, setLoggedIn] = useAtom(isLoggedIn);
  const dataFilled_API = useMutation(dataFilled);
  const { data, isError, isLoading } = useQuery("user", getUserDetails);



  return (
    <PrivateRouteReactContext.Provider value={
    {
      authorities: [data?.job_seeker === false ? "ADMIN": "USER"],
      unAuthorizedUrl: "/403",
      loginUrl: "/login",
      isAuthenticated: data?.loggedin,
      redirectUrl: "/"
    }}>
      <Navbar loggedIn={data?.loggedin} />
      {!isLoading ? 
      <Router history={createBrowserHistory}>
        <Switch>
          {/* User Routes */}
          <PrivateRouteReact path="/upload-cv" hasAuthorities={["USER"]} exact>
            {!data?.loggedin ? <Redirect to={"/"} />: <TestComp userDetail={data} />}
          </PrivateRouteReact>
          <PrivateRouteReact path="/audio-rec" component={AudioRec} hasAuthorities={["USER"]} exact />
          <PrivateRouteReact path="/job-opening" component={JobOpening} hasAuthorities={["USER"]} exact />
          <PrivateRouteReact path="/sample-interview" component={SampleInterview} hasAuthorities={["USER"]} exact />
          <PrivateRouteReact path="/rules" component={Rules} hasAuthorities={["USER"]} exact />
          <PrivateRouteReact path="/questions" component={Questions} hasAuthorities={["USER"]} exact />
          <PrivateRouteReact path="/success" component={Success} hasAuthorities={["USER"]} exact />

          {/* Admin Routes */}
          <PrivateRouteReact path="/admin-home" component={AdminHome}  hasAuthorities={["ADMIN"]} exact />
          <PrivateRouteReact path="/admin-home/:position_id" component={AdminHome} hasAuthorities={["ADMIN"]} exact />
          <PrivateRouteReact path="/admin-home/:position_id/:user_id/" component={UserResult} hasAuthorities={["ADMIN"]} />
          <Route path={"/login"} exact>
            {!data.loggedin ? <Home />: <Redirect to={"/"} />}
          </Route>
          <Route path={"/*"} exact>
            <Redirect to={!data?.loggedin ? "/login": data?.job_seeker === false ? "/admin-home": "/upload-cv"} />
          </Route>
        </Switch>
      </Router>: "Loading"}
    
    </PrivateRouteReactContext.Provider>
  );
};

export default App;
