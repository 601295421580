import React from "react";
import Navbar from "../../../Parts/Navbar";
import QandA from "../../../Parts/QandA";

const Index = () => {
  return (
    <div>
      <QandA />
    </div>
  );
};

export default Index;
