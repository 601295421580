import React, { useEffect, useState } from "react";
import "./openings.css";
import { Link, useHistory } from "react-router-dom";
// Store
import { useAtom } from "jotai";
import { jobRole, currentQuesstion } from "../../../Store/Users";
import { getJobList } from "../../API_CALLS/Users";
import { useQuery } from "react-query";

const Index = () => {
  const [jobs, setJobs] = useAtom(jobRole);

  const [, setCurrentQue] = useAtom(currentQuesstion);
  const { data } = useQuery("jobs", getJobList, { refetchOnMount: true });
  const [isBlocked, setIsBlocked] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setCurrentQue(0);
    if (data !== undefined) {
      setJobs(data);
    }
  }, [data]);

  useEffect(() => {
    if (window.chrome !== undefined) {
      navigator.permissions
        .query({ name: "microphone" })
        .then(function (permissionStatus) {
          if (permissionStatus.state === "prompt") {
            setIsBlocked(true);
            navigator.mediaDevices
              .getUserMedia({ video: false, audio: true })
              .then((mediaStream) => {
                setIsBlocked(false);
                window.location.reload();
                window.requestStream = mediaStream;
              })
              .catch((err) => {
                console.log(err);
                if (err) {
                  setIsBlocked(true);
                }
              });
          }
          if (permissionStatus.state === "granted") {
            setIsBlocked(false);
          }
          if (permissionStatus.state === "denied") {
            setIsBlocked(true);
          }
        });
    }
  }, []);

  return (
    <div className="custom-container openings">
      <h5>Sample Assessment</h5>
      <div className="row">
        <div className="col-md-8">
          <div className="card">
            <ul>
              <li>
                Use this sample assessment to get comfortable with our Hiring
                tool.
              </li>
              <li>
                We recommend you to use latest version of{" "}
                <a href="https://www.google.com/intl/en/chrome/">
                  Google Chrome
                </a>{" "}
                to avoid any issues during your interview.
              </li>
              <li>
                You can take this assessment as many times as you want, without
                affecting your actual interview.
              </li>
              <li>
                You can also use this sample assessment to test your
                microphone/mic settings with our system. The recording for
                sample interview is discarded as soon as you leave the sample
                interview screen.
              </li>
            </ul>
            <div className="text-end">
              <Link to="/sample-interview" className="btn btn-primary col-md-3">
                Sample Interview Link
              </Link>
            </div>
          </div>
        </div>
      </div>
      {isBlocked ? (
        <h6 className="mt-4 text-center text-danger  ">
          Please enable Microphone before starting interview
        </h6>
      ) : null}

      <table className="table table-hover">
        <thead className="table-success">
          <tr>
            <td>Job Opening and Company</td>
            <td>Link</td>
            <td>Status</td>
          </tr>
        </thead>
        <tbody className="table-light">
          {jobs.map(({ job_title, status, job, id }, key) => {
            return (
              <tr key={key}>
                <td>{job_title}</td>
                <td>
                  {status === "SHORTLISTED" ||
                  status === "REJECTED" ||
                  status === "EVALUATION" ? (
                    "-"
                  ) : (
                    <Link
                      onClick={(e) => {
                        if (isBlocked) {
                          if (window.chrome !== undefined) {
                            navigator.permissions
                              .query({ name: "microphone" })
                              .then(function (permissionStatus) {
                                if (permissionStatus.state === "prompt") {
                                  setIsBlocked(true);
                                  navigator.mediaDevices
                                    .getUserMedia({ video: false, audio: true })
                                    .then((mediaStream) => {
                                      setIsBlocked(false);
                                      window.location.reload();
                                      window.requestStream = mediaStream;
                                    })
                                    .catch((err) => {
                                      if (err) {
                                        setIsBlocked(true);
                                      }
                                    });
                                }
                                if (permissionStatus.state === "granted") {
                                  setIsBlocked(false);
                                }
                                if (permissionStatus.state === "denied") {
                                  setIsBlocked(true);
                                }
                              });
                          }
                          e.preventDefault();
                        } else {
                          // history.push(`/rules?job_id=${job}&id=${id}`);
                        }
                      }}
                      to={`/rules?job_id=${job}&id=${id}`}
                    >
                      Start Interview
                    </Link>
                  )}
                </td>
                <td>{status}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Index;
