import React, { useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SpinnerCircular } from "spinners-react";
// Schema
import validationPortfolio from "./validation/validation_portfolio";
// API Calls
import { useMutation } from "react-query";
import { candidatePortfolio } from "../../../../API_CALLS/Sign-up";
// Store
import { useAtom } from "jotai";
import { sign_up_pointer } from "../../../../../Store/Signup";

const Portfolio = () => {
  const formOptions = { resolver: yupResolver(validationPortfolio) };
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);
  const { fields, append, remove } = useFieldArray({
    control,
    name: "portfolio",
  });

  useEffect(() => {
    append({ name_of_project: "", description: "", link: "" });
  }, []);

  const candidatePortfolio_API = useMutation(candidatePortfolio);
  const [pointer, setPointer] = useAtom(sign_up_pointer);

  return (
    !candidatePortfolio_API.isLoading ?
    <>
      <div className="container mt-4">
        <div className="row justify-content-center">
          <form
            className="col-md-8 mt-5"
            onSubmit={handleSubmit((data) => {
              candidatePortfolio_API.mutateAsync(data).then(() => {
                setPointer(pointer + 1);
              }).catch((e) => {
                console.log("Error: ", e);
              })
            })}
          >
            {fields.map((item, index) => {
              return (
                <div key={item.id}>
                  <div className="row mt-2">
                    <label
                      htmlFor="inputEmail3"
                      className="col-sm-2 col-form-label"
                    >
                      Name of project*
                    </label>
                    <div className="col-sm-10">
                      <Controller
                        name={`portfolio.${index}.name_of_project`}
                        control={control}
                        defaultValue={item.name_of_project}
                        render={({ field }) => (
                          <input
                            type="text"
                            className={
                              "form-control " +
                              (errors.portfolio?.[index]?.name_of_project &&
                                "border-danger")
                            }
                            {...field}
                          />
                        )}
                      />
                      <span className="text-danger">
                        {errors.portfolio?.[index]?.name_of_project?.message}
                      </span>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <label
                      htmlFor="inputEmail3"
                      className="col-sm-2 col-form-label"
                    >
                      Description
                    </label>
                    <div className="col-sm-10">
                      <Controller
                        name={`portfolio.${index}.description`}
                        control={control}
                        defaultValue={item.description}
                        render={({ field }) => (
                          <input
                            type="text"
                            className={
                              "form-control " +
                              (errors.portfolio?.[index]?.description &&
                                "border-danger")
                            }
                            {...field}
                          />
                        )}
                      />
                      <span className="text-danger">
                        {errors.portfolio?.[index]?.description?.message}
                      </span>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <label
                      htmlFor="inputEmail3"
                      className="col-sm-2 col-form-label"
                    >
                      Link/URL*
                    </label>
                    <div className="col-sm-10">
                      <Controller
                        name={`portfolio.${index}.link`}
                        control={control}
                        defaultValue={item.link}
                        render={({ field }) => (
                          <input
                            type="text"
                            className={
                              "form-control " +
                              (errors.portfolio?.[index]?.link &&
                                "border-danger")
                            }
                            {...field}
                          />
                        )}
                      />
                      <span className="text-danger">
                        {errors.portfolio?.[index]?.link?.message}
                      </span>
                    </div>
                  </div>
                  <div className="text-end mt-4">
                      {fields.length === index + 1 ? <span className="btn btn-dark" onClick={() => append({ name_of_project: "", description: "", link: "" })}>Add another project</span>: null }
                        &nbsp;&nbsp;&nbsp;
                     {fields.length !== 1 ? <button className="btn btn-danger" onClick={() => remove(index)}>Delete</button>: null}
                  </div>
                </div>
              );
            })}
            <div className="text-center mt-4 pb-4">
              <span className="btn btn-dark" onClick={() => {
                candidatePortfolio_API.mutateAsync({skip: true}).then(() => {
                  setPointer(pointer + 1);
                }).catch((e) => {
                  console.log("Error: ", e);
                })
                  }}>Skip</span>
              &nbsp;&nbsp;&nbsp;
              <button className="btn btn-success" type="submit">Next</button>
            </div>
          </form>
        </div>
      </div>
    </>: 
    <div className="text-center">
      <SpinnerCircular size={50} thickness={100} speed={100} color="#36ad47" secondaryColor="rgba(0, 0, 0, 0.44)" />
    </div> 
  );
};

export default Portfolio;
