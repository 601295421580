import axios from "axios";

axios.defaults.withCredentials = true;
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

// Get All Jobs
const getJobList = async () => {
  const { data } = await axios.get(
    process.env.REACT_APP_ROOT_URL + "/hiring/admin/job_list",
    {
      withCredentials: true,
    }
  );
  return data;
};

// Get candidate by all details
const getCandidates = async (key) => {
  const { data } = await axios.get(
    process.env.REACT_APP_ROOT_URL + `/hiring/admin/all_detail/${key}`,
    {
      withCredentials: true,
    }
  );
  return data;
};

// Get short listed candidate
const getShortListed = async (job_id) => {
  const { data } = await axios.get(
    process.env.REACT_APP_ROOT_URL + `/hiring/admin/shortlist_detail/${job_id}`,
    {
      withCredentials: true,
    }
  );
  return data;
};

// Get answers of a candidate
const getAnswers = async (job_id) => {
  const { data } = await axios.get(
    process.env.REACT_APP_ROOT_URL + `/hiring/admin/rec_view/${job_id}`,
    {
      withCredentials: true,
    }
  );
  return data;
};

// Shortlist a candidate
const shortListCandidate = async (job_id) => {
  const { data } = await axios.get(
    process.env.REACT_APP_ROOT_URL + `/hiring/admin/shortlist/${job_id}`,
    {
      withCredentials: true,
    }
  );
  return data;
};

// reject a candidate
const rejectCandidate = async (job_id) => {
  const { data } = await axios.get(
    process.env.REACT_APP_ROOT_URL + `/hiring/admin/reject/${job_id}`,
    {
      withCredentials: true,
    }
  );
  return data;
};

// Add Invite
const addInvite = async (emails) => {
  const { data } = await axios.post(
    process.env.REACT_APP_ROOT_URL + "/hiring/admin/add_invites",
    emails,
    {
      headers: {
        "content-type": "application/json",
      },
      withCredentials: true,
    }
  );
  return data;
};

// List of Company
const getCompanies = async () => {
  const { data } = await axios.get(
    process.env.REACT_APP_ROOT_URL + `/hiring/admin/get_companies`,
    {
      withCredentials: true,
    }
  );
  return data;
};

// Get List of Questions

const getQuestions = async (companyID) => {
  const { data } = await axios.get(
    process.env.REACT_APP_ROOT_URL + `/hiring/admin/get_batches/${companyID}`,
    {
      withCredentials: true,
    }
  );
  return data;
};

// Add Questions
const addQuestions = async (questions) => {
  const { data } = await axios.post(
    process.env.REACT_APP_ROOT_URL + "/hiring/admin/add_question",
    questions,
    {
      headers: {
        "content-type": "application/json",
      },
      withCredentials: true,
    }
  );
  return data;
};

// Add Job
const addJob = async (job) => {
  const { data } = await axios.post(
    process.env.REACT_APP_ROOT_URL + "/hiring/admin/add_job",
    job,
    {
      headers: {
        "content-type": "application/json",
      },
      withCredentials: true,
    }
  );
  return data;
};

// Add Job
const addNote = async (candidate) => {
  const { data } = await axios.post(
    process.env.REACT_APP_ROOT_URL + "/hiring/admin/addnote/" + candidate.id,
    {notes: candidate.note},
    {
      headers: {
        "content-type": "application/json",
      },
      withCredentials: true,
    }
  );
  return data;
};

const addCommonNote = async (candidate) => {
  const { data } = await axios.post(
    process.env.REACT_APP_ROOT_URL + "/hiring/admin/add_common_note/" + candidate.id,
    {note: candidate.note},
    {
      headers: {
        "content-type": "application/json",
      },
      withCredentials: true,
    }
  );
  return data;
};

export {
  getJobList,
  getCandidates,
  getShortListed,
  getAnswers,
  shortListCandidate,
  rejectCandidate,
  addInvite,
  getCompanies,
  addQuestions,
  getQuestions,
  addJob,
  addNote,
  addCommonNote
};
