import React from "react";
import "./Header.css";

const reverseTimer = (timer) => {
  if (timer <= 0) {
    return "00:00";
  }
  const minutes = `${Math.floor(timer / 60)}`;
  return `0${minutes % 60}`.slice(-2) + "." + `0${timer % 60}`.slice(-2);
};

const Header = ({
  total_timer,
  quesion_text,
  question_sno,
  total_questions,
  question_timer,
}) => {
  return (
    <div className="custom-container que-header">
      {total_timer < 20 ? (
        <div className="question-remaining-time text-center mt-4">
          <h6 className="text-danger">
            Your Interview time is up. Answers will be submitted Automatically.
          </h6>
          <div className="card"></div>
        </div>
      ) : null}

      <div className="time-remaining">
        <div className="card">
          <div className="row">
            <div className="col-2">
              <i className="fas fa-stopwatch"></i>
            </div>
            <div className="col-7">Time remaining in interview</div>
            <div className="col-3">{reverseTimer(total_timer)}</div>
          </div>
        </div>
      </div>

      {question_timer < 15 ? (
        <div className="question-remaining-time text-center mt-4">
          <h6 className="text-danger">
            Your Current question time is getting up your answers will be
            submitted Automatically.
          </h6>
          <div className="card"></div>
        </div>
      ) : null}

      <div className="question-card">
        <h3>
          <u>
            Question {question_sno}/{total_questions}
          </u>
        </h3>
        <br />
        <p>{quesion_text}</p>
      </div>
    </div>
  );
};

export default Header;
